// ShowingCard.js
import React, { useEffect, useState, useContext } from 'react';
import { formatDate } from '../../utilities/utility';
import { UserContext } from '../../context/userContext';
import {
  Card,
  PropertyAddress,
  AgentName,
  StatusBadge,
  RequestedTime,
  DetailItem,
  ConfirmButton,
  MoreActionsButton,
  MoreBadge,
  AppItem,
  SpaceDiv,
  FlexDiv,
  BulletIcon,
} from './showing-card.styles'; // Adjust the import path as needed
import { ReactComponent as Apps } from '../../assets/apps.svg'
import { ReactComponent as Timer } from '../../assets/showing-timer.svg'
import { ReactComponent as Calendar } from '../../assets/calendar.svg'
import { ReactComponent as TimeCircle } from '../../assets/Time Circle.svg'


const ShowingCard = ({ showingRequest, setActiveTab, toggleModal }) => {

    const { property, agent, status, requestedTime, noticePeriod, numApps, confirmedTime, rescheduledTime } = showingRequest
    const { user } = useContext(UserContext)
    // State for formatted date and time
    const [formattedDate, setFormattedDate] = useState(null);
    const [formattedTime, setFormattedTime] = useState(null);

    // Format the time parts, ensuring two digits
    useEffect(() => {
        let dateObj = null;
        if(status === "Upcoming"){
            dateObj = new Date(confirmedTime);
        }else if (status === "Rescheduled" || status === "Agent Reschedule") {
            dateObj = new Date(rescheduledTime);
        } else {
            dateObj = new Date(requestedTime[0]);
        }
        const dateInfo = formatDate(dateObj);
        setFormattedDate(dateInfo['formattedDate']);
        setFormattedTime(dateInfo['formattedTime']);
    },[confirmedTime,rescheduledTime])
    

    const cardClick = (event) => {
        if(event.target.innerText === "Confirm"){
            setActiveTab('confirm');
        }else if (event.target.innerText === "Intervene"){
            setActiveTab('other');
        }else{
            setActiveTab('info');
        }
        toggleModal();
    }

    return (
        <Card onClick = { (event) => cardClick(event)}>
            <PropertyAddress>{property.address}</PropertyAddress>
            <SpaceDiv>
                <AgentName>{agent.name}</AgentName>
                <StatusBadge status={status}>
                    <BulletIcon/>
                    {status}
                </StatusBadge>
            </SpaceDiv>
            <RequestedTime>
                {
                    status === 'Upcoming' ? 
                    <span>Confirmed time:</span>
                    :
                    status === "Rescheduled" || status === "Agent Reschedule"? 
                    <span>Rescheduled time:</span>
                    :
                    <span>Requested time:</span>
                }
            </RequestedTime>
            <SpaceDiv>
                <DetailItem>
                    <Calendar/> {formattedDate} 
                </DetailItem>
                <FlexDiv style={{gap: '5px' }}>
                    <DetailItem>
                        <TimeCircle/>{formattedTime}
                    </DetailItem>
                    {
                        requestedTime.length - 1 > 0 && (confirmedTime === undefined || confirmedTime === '') && (rescheduledTime === undefined || rescheduledTime === '') &&
                        <MoreBadge>+ {requestedTime.length - 1} more</MoreBadge>
                    }
                </FlexDiv>
            </SpaceDiv>
            <SpaceDiv>
                {
                    (status !== "Declined" && status !== "Cancelled" && status !== "Completed" && noticePeriod && noticePeriod[0] != '-') &&
                    <DetailItem>
                        <span style={{height:'20px'}}><Timer/></span>
                        {noticePeriod}
                    </DetailItem>
                }
                {
                    numApps > 0 &&
                    <AppItem>
                        <Apps stroke="#E45A5C"/> {numApps} Apps
                    </AppItem>
                }
            </SpaceDiv>
            <FlexDiv style={{gap: '10px' , marginTop: '5px'}}>
                {
                    user?.userType !== 'view_only' && "Cancelled" && status !== 'Completed' && status !== "Declined" &&
                    <MoreActionsButton>Intervene</MoreActionsButton>
                }
                {
                    user?.userType !== 'view_only' && status !== "Upcoming" && status !== "Completed" && status !== "Declined" && status !== "Rescheduled" &&
                    status !== "Cancelled" && 
                    <ConfirmButton>Confirm</ConfirmButton>
                }
                {/* {
                    status === 'Completed' &&
                    <CompletedButton>Completed</CompletedButton>
                } */}
            </FlexDiv>
        </Card>
    );
};

export default ShowingCard;
