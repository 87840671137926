import { Search, X } from 'lucide-react'
import { useState, useEffect, useRef } from "react"

function SearchDropdown({
    placeholder = "Search...",
    width = "250px",
    height = "48px",
    maxItems = 6,
    field,
    onSelect = () => { },
    onClose = () => { },
    noOptionsMessage = "No options found",
    fetchData,
    reset
}) {
    const [isLoading, setIsLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [query, setQuery] = useState("")
    const [selectedIndex, setSelectedIndex] = useState(-1)
    const inputRef = useRef(null)
    const dropdownRef = useRef(null)
    const listRef = useRef(null)
    const [items, setItems] = useState([])

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && inputRef.current && !inputRef.current.contains(event.target)) {
                console.log("Inside click outside", placeholder, isOpen)
                if(isOpen) {
                    setIsOpen(false)
                    setIsLoading(true)
                    onClose()
                }
                setSelectedIndex(-1)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        return () => document.removeEventListener("mousedown", handleClickOutside)
    }, [isOpen])


    useEffect(() => {
        if(isOpen) setIsLoading(true)
    }, [isOpen])

    useEffect(() => {
        if (reset) setQuery("")
    }, [reset])

    // Populate the data when the dropdown is opened
    useEffect(() => {
        const populateData = async () => {
            if (!isOpen) return
            console.log(field, query);
            const data = await fetchData(field, query);
            setItems(data)
            // Artifical delay using timeout
            setTimeout(() => setIsLoading(false), 500)
            // setIsLoading(false)
        }
        populateData()
    }, [isOpen, query])

    

    // Handle keyboard navigation
    const handleKeyDown = (e) => {
        if (!isOpen) {
            if (e.key === "ArrowDown" || e.key === "Enter") {
                if(!isOpen) setIsOpen(true)
            }
            return
        }

        switch (e.key) {
            case "ArrowDown":
                e.preventDefault()
                setSelectedIndex((prev) =>
                    prev < items.length - 1 ? prev + 1 : prev
                )
                break
            case "ArrowUp":
                e.preventDefault()
                setSelectedIndex((prev) => (prev > 0 ? prev - 1 : prev))
                break
            case "Enter":
                e.preventDefault()
                if (selectedIndex >= 0) {
                    handleSelect(items[selectedIndex])
                }
                break
            case "Escape":
                setIsOpen(false)
                setSelectedIndex(-1)
                break
            default:
                break
        }
    }

    useEffect(() => {
        if (selectedIndex >= 0 && listRef.current) {
            const items = listRef.current.getElementsByClassName('dropdown-item')
            const selectedItem = items[selectedIndex]
            if (selectedItem) {
                selectedItem.scrollIntoView({ block: 'nearest' })
            }
        }
    }, [selectedIndex])

    const handleSelect = (item) => {
        console.log("Inside handleSelect")
        setQuery(item.value)
        onSelect(item)
        setIsOpen(false)
        setSelectedIndex(-1)
    }

    const handleRemove = () => {
        setQuery("")
        setIsLoading(true)
        setIsOpen(true)
        setSelectedIndex(-1)
        onClose()
        inputRef.current.focus()
    }

    return (
        <div
            className="relative"
            ref={dropdownRef}
            style={{ width }}
        >
            <div className="relative">
                <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
                <input
                    type="text"
                    className="w-full rounded-lg border border-gray-200 py-2.5 pl-10 pr-7 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                    placeholder={placeholder}
                    value={query}
                    style={{ height }}
                    ref={inputRef}
                    onChange={(e) => {
                        setQuery(e.target.value)
                        setIsOpen(true)
                        setSelectedIndex(-1)
                        onClose()
                    }}
                    onClick={() => {
                        if(!isOpen) setIsOpen(true)
                    }}
                    onKeyDown={handleKeyDown}
                />
                <X className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400 cursor-pointer"
                    onClick={handleRemove} />
            </div>

            {isOpen && (
                <div
                    className="absolute z-10 mt-1 w-full rounded-lg border border-gray-200 bg-white shadow-lg"
                    ref={listRef}
                >
                    <div
                        className="overflow-y-auto"
                        style={{ maxHeight: `${Number(height.replace('px', '')) * maxItems}px` }}
                    >
                        {isLoading ? (
                            <div className="px-4 py-3 text-sm text-gray-500 text-center">Loading...</div>
                        ) : items.length > 0 ? (
                            items.map((item, index) => (
                                <div
                                    key={`${item.id}-${index}`}
                                    className={`dropdown-item px-4 py-3 text-sm cursor-pointer ${selectedIndex === index ? 'bg-gray-300' : ''
                                        }`}
                                    onMouseDown={(e) => {
                                        e.preventDefault();
                                        console.log("Inside onMouseDown");
                                        handleSelect(item);
                                    }}
                                    onMouseEnter={() => setSelectedIndex(index)}
                                >
                                    {item.value}
                                </div>
                            ))
                        ) : (
                            <div className="px-4 py-3 text-sm text-gray-500 text-center">{noOptionsMessage}</div>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default SearchDropdown;