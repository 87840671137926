import React, { useState, useEffect, useContext } from "react";
import FormInput from "../form-input/form-input";
import { camelCaseToTitle, formatCurrency } from "../../utilities/utility";
import { UserContext } from "../../context/userContext";
import moment from "moment";
import { toast } from 'react-toastify'
import {
    ModalWrapper,
    ModalOverlay,
    Title,
    SpaceDiv,
    FlexDiv,
    CloseButton,
    WrapDiv,
    FormContainer,
    SectionTitle,
    Section,
    Label,
    Payment,
    PaymentContainer,
    PaymentTitle,
    Heading,
    FlexColumnDiv,
    SubmitButton,
    PreviousButton,
    TextInput
} from './create-lease-modality.styles'
import { ApiContext } from "../../context/apiContext";

const CreateLeaseModality = ({modalId, onClose, updateDeal}) => {
    const api = useContext(ApiContext)
    const { user } = useContext(UserContext)
    // const [sameAsLandlord, setSameAsLandlord] = useState(false);
    const [isFocus, setIsFocus] = useState('Inputs') // Preview
    const [showSubmit, setShowSubmit] = useState(true);
    const [leaseInputs, setLeaseInputs] = useState({
        leaseTerms: {
            paymentDate: '1st'
        },
        rentalTerms: {
            firstMonthStartDate: "",  // First Month Start date
            firstMonthEndDate: "",    // First month end date
            firstMonthRent: "",       // First Month’s Rent
            lastMonthRent: "",        // Last Month’s Rent
            securityDeposit: "",      // Security Deposit
        },
        property: {
            rooms: 'All'
        },
        landlord: {
            name: '',
            // address:'',
            // cityStateZip: '',
            // phone: '',
            email: ''
        },
        maintenance: {
            name: '',
            email: '',
            phone: '',
            address: '',
            cityStateZip: ''
        },
        tenantCertification: {
            leadPresence: false,
            recordsAvailable: false,
            tenantAcknowledgement: 'No Documents', //'All Documents','MA Lead Law Notification'
            // agentsAcknowledgement: false
        },
        otherProvisions: '-',
        standardProvisions: '-',
        waterAndSewerAddendum: false,
        taxDocument: 'w8',
        taxApplicant: null
    })
    const [previewData, setPreviewData] = useState({
        leaseId: null,
        leaseDoc: '',
        taxFieldInput: ''
    })
 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await api.get(`/api/lease/lease_input`,{dealId: modalId});
                const rentalTerms = {
                    firstMonthStartDate : data.leaseTerms?.moveIn,
                    firstMonthEndDate : addOneMonth(data.leaseTerms?.moveIn),
                    firstMonthRent : data.dealPrice,
                    lastMonthRent : data.dealPrice,
                    securityDeposit : data.dealPrice
                }
                console.log(rentalTerms)
                setLeaseInputs(prevState => ({
                    ...prevState,
                    leaseTerms: { ...prevState.leaseTerms, ...data.leaseTerms },
                    rentalTerms : rentalTerms,
                    property: { ...prevState.property, ...data.property },
                    deposits: { ...prevState.deposits, ...data.deposits },
                    agent: data.agent,
                    applicants: data.applicants,
                    termRent: data?.termRent || '',
                    depositPaid : data?.depositPaid,
                    depositPaidType: data?.depositPaidType,
                    dealPrice: data?.dealPrice,
                    taxApplicant: data.applicants[0].id
                    // Add more properties as needed
                }));
            } catch (error) {
                toast.error(`There was an error loading the data: ${error.message}`);
            }
        };

        if (modalId && modalId !== undefined && modalId !== null) {
            fetchData();
        }
    }, [modalId]);

    // console.log(leaseInputs)


    function getMonthDayDifference(date1, date2) {
        const startDate = new Date(date1);
        const endDate = new Date(date2);
        let months = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
        let days = endDate.getDate() - startDate.getDate();
    
        // Adjust for negative days
        if (days < 0) {
            months -= 1;
            const previousMonth = new Date(endDate.getFullYear(), endDate.getMonth(), 0);
            days += previousMonth.getDate();
        }
    
        return { months, days };
    }

    function addOneMonth(dateString) {
        // Parse the input date string to a Date object
        const date = new Date(dateString);
        
        // Add one month
        date.setMonth(date.getMonth() + 1);
    
        // Format the resulting date back to YYYY-MM-DD
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    }

    const handleChange = (section = null, key, value) => {
        setLeaseInputs(prevState => {
            // If there's a section, update within that section
            if (section) {
                return {
                    ...prevState,
                    [section]: {
                        ...prevState[section],
                        [key]: value,
                    },
                };
            }
            // If there's no section, update the top-level property
            return {
                ...prevState,
                [key]: value,
            };
        });
        if(key == "moveIn"){
            const difference = getMonthDayDifference(value, leaseInputs.leaseTerms.moveOut)
            const duration = `${difference.months ? difference.months + " months " : ""}` + `${difference.days ? difference.days + " days" : ""}`;
            const termRentNew = (leaseInputs.dealPrice * difference.months + ((leaseInputs.dealPrice)/30) * difference.days)
            setLeaseInputs(prev => {
                return {
                    ...prev,
                    leaseTerms: {
                        ...prev.leaseTerms, 
                        leaseDuration: duration
                    },
                    termRent: Number(termRentNew.toFixed(2))
                };
            });            
        }
        if(key == "moveOut"){
            const difference = getMonthDayDifference(leaseInputs.leaseTerms.moveIn, value)
            const duration = `${difference.months ? difference.months + " months " : ""}` + `${difference.days ? difference.days + " days" : ""}`;
            const termRentNew = (leaseInputs.dealPrice * difference.months + ((leaseInputs.dealPrice)/30) * difference.days)
            setLeaseInputs(prev => {
                return {
                    ...prev,
                    leaseTerms: {
                        ...prev.leaseTerms, 
                        leaseDuration: duration
                    },
                    termRent: Number(termRentNew.toFixed(2))
                };
            });       
        }
    };    

    // const toggleSameAsLandlord = () => {
    //     setSameAsLandlord(!sameAsLandlord);
    //     if (!sameAsLandlord) { // If currently not same, make them the same.
    //         setLeaseInputs(prevState => ({
    //             ...prevState,
    //             // maintenance: { ...prevState.landlord },
    //         }));
    //     } else { // If currently the same, revert to original (empty or whatever logic you prefer).
    //         setLeaseInputs(prevState => ({
    //             ...prevState,
    //             // maintenance: {
    //             //     name: '',
    //             //     email: '',
    //             //     phone: '',
    //             //     address: '',
    //             //     cityStateZip: '',
    //             // },
    //         }));
    //     }
    // };
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        // console.log(leaseInputs)

        try {
            toast.info('Lease being generated. Please allow a moment.')
            const data = await api.post(`/api/lease/preview_draft`,leaseInputs, {dealId: modalId}); // Convert the JavaScript object to a JSON string
            setPreviewData(data)
        } catch (error) {
            toast.error(`Error during API call: ${error}`);
        } finally {
            setIsFocus('Preview')
        }
    }

    const handleSendLease = async (event) => {
        event.preventDefault();
        // console.log(leaseInputs)

        try {
            toast.info('Lease being sent for signing to applicants. Please allow a moment.')
            setShowSubmit(false);
            const data = await api.post(`/api/lease/send_lease`, previewData, {dealId: modalId}); // Convert the JavaScript object to a JSON string
            updateDeal(data)
            toast.success('The lease has been shared with the applicants and agent')
            onClose();
        } catch (error) {
            toast.error(`Error during API call: ${error}`);
        }
    }

    // console.log(leaseInputs)
    
    return (
        <ModalOverlay onClick={onClose}>
            <ModalWrapper
                onClick={(e) => e.stopPropagation()}
            >
                {
                    isFocus === 'Inputs'?
                    <>
                        <SpaceDiv>
                            <Title>Create Lease Agreement</Title>
                            <FlexDiv>
                                <span style={{fontSize:"14px"}}>Close</span>
                                <CloseButton onClick={onClose}>X</CloseButton>
                            </FlexDiv>
                        </SpaceDiv>
                        <FormContainer onSubmit={handleSubmit}>
                            <Section>
                                <SectionTitle>Property Information</SectionTitle>
                                <WrapDiv>
                                    {
                                        Object.entries(leaseInputs.property)?.filter(([key,_] )=> key !=='id').map(([key, value]) =>
                                            <FormInput
                                                label = {camelCaseToTitle(key)}
                                                type = "text"
                                                isRequired={true}
                                                flexBasis={true}
                                                defaultValue={value}
                                                handleChange={(value) => handleChange('property', key, value)}
                                            />    
                                        )
                                    }
                                </WrapDiv>
                            </Section>
                            <Section>
                                <SectionTitle>Landlord Information</SectionTitle>
                                <WrapDiv>
                                    {
                                        Object.entries(leaseInputs.landlord)?.map(([key, value]) =>
                                            <FormInput
                                                label = {camelCaseToTitle(key)}
                                                type = "text"
                                                isRequired={true}
                                                flexBasis={true}
                                                defaultValue={value}
                                                handleChange={(value) => handleChange('landlord', key, value)}
                                            />    
                                        )
                                    }
                                </WrapDiv>
                            </Section>
                            <Section>
                                <SectionTitle>Lease Terms</SectionTitle>
                                <WrapDiv>
                                    {
                                        Object.entries(leaseInputs.leaseTerms)?.filter(([key,_]) => key !=="paymentDate" && key !== "leaseDuration")
                                        .map(([key, value]) =>
                                            <FormInput
                                                label = {camelCaseToTitle(key)}
                                                type = "date"
                                                isRequired={true}
                                                flexBasis={true}
                                                defaultValue={moment.utc(new Date(value)).format('YYYY-MM-DD')}
                                                handleChange={(value) => handleChange('leaseTerms', key, moment(value).format('YYYY-MM-DD'))}
                                            />    
                                        )
                                    }
                                    {
                                        Object.entries(leaseInputs.leaseTerms)?.filter(([key,_]) => key ==="paymentDate" || key === "leaseDuration")
                                            .map(([key, value]) =>
                                                <FormInput
                                                    label = {camelCaseToTitle(key)}
                                                    type = "text"
                                                    isRequired={true}
                                                    flexBasis={true}
                                                    defaultValue={key === "paymentDate" ? `${value} of every Month`:value}
                                                    handleChange={(value) => handleChange('leaseTerms', key, value)}
                                                />    
                                            )
                                    }
                                    <FormInput
                                        label = "Term Rent"
                                        type = "text"
                                        isRequired={true}
                                        flexBasis={true}
                                        defaultValue={leaseInputs?.termRent || ''}
                                        handleChange={(value) => handleChange(null, 'termRent', value)}
                                    />
                                    {
                                        leaseInputs?.deposits &&
                                        <FormInput
                                            label = "Monthly Rent Installment"
                                            type = "text"
                                            isRequired={true}
                                            flexBasis={true}
                                            defaultValue={leaseInputs.dealPrice}
                                            disabled={true}
                                        />
                                    }
                                </WrapDiv>
                            </Section>
                            <Section>
                                <SectionTitle>Rental Security Terms</SectionTitle>
                                <WrapDiv>
                                    {/* First Month Start date */}
                                    <FormInput
                                        label="First Month Start date"
                                        type="date"
                                        isRequired={true}
                                        flexBasis={true}
                                        defaultValue={
                                            leaseInputs?.rentalTerms?.firstMonthStartDate
                                                ? moment.utc(new Date(leaseInputs.rentalTerms.firstMonthStartDate)).format("YYYY-MM-DD")
                                                : ""
                                        }
                                        handleChange={(value) =>
                                            handleChange("rentalTerms", "firstMonthStartDate", moment(value).format("YYYY-MM-DD"))
                                        }
                                    />

                                    {/* First month end date */}
                                    <FormInput
                                        label="First month end date"
                                        type="date"
                                        isRequired={true}
                                        flexBasis={true}
                                        defaultValue={
                                            leaseInputs?.rentalTerms?.firstMonthEndDate
                                                ? moment.utc(new Date(leaseInputs.rentalTerms.firstMonthEndDate)).format("YYYY-MM-DD")
                                                : ""
                                        }
                                        handleChange={(value) =>
                                            handleChange("rentalTerms", "firstMonthEndDate", moment(value).format("YYYY-MM-DD"))
                                        }
                                    />

                                    {/* First Month’s Rent */}
                                    <FormInput
                                        label="First Month’s Rent"
                                        type="text"
                                        isRequired={true}
                                        flexBasis={true}
                                        defaultValue={leaseInputs?.rentalTerms?.firstMonthRent || ""}
                                        handleChange={(value) =>
                                            handleChange("rentalTerms", "firstMonthRent", value)
                                        }
                                    />

                                    {/* Last Month’s Rent */}
                                    <FormInput
                                        label="Last Month’s Rent"
                                        type="text"
                                        isRequired={true}
                                        flexBasis={true}
                                        defaultValue={leaseInputs?.rentalTerms?.lastMonthRent || ""}
                                        handleChange={(value) =>
                                            handleChange("rentalTerms", "lastMonthRent", value)
                                        }
                                    />

                                    {/* Security Deposit */}
                                    <FormInput
                                        label="Security Deposit"
                                        type="text"
                                        isRequired={true}
                                        flexBasis={true}
                                        defaultValue={leaseInputs?.rentalTerms?.securityDeposit || ""}
                                        handleChange={(value) =>
                                            handleChange("rentalTerms", "securityDeposit", value)
                                        }
                                    />
                                </WrapDiv>
                            </Section>
                            <Section>
                                <SectionTitle>Applicant Information</SectionTitle>
                                {
                                    leaseInputs?.applicants &&
                                    <WrapDiv>
                                        <FormInput
                                            label = "Names"
                                            type = "text"
                                            isRequired={true}
                                            flexBasis={true}
                                            defaultValue={camelCaseToTitle(leaseInputs?.applicants.map(obj => obj.name).join(', '))}
                                            // handleChange={(value) => handleChange('applicants', key, value)}
                                            disabled={true}
                                        />  
                                    </WrapDiv>  
                                }
                                {
                                    leaseInputs?.applicants &&
                                    <WrapDiv>
                                        <FormInput
                                            label = "Emails"
                                            type = "text"
                                            isRequired={true}
                                            flexBasis={true}
                                            defaultValue={camelCaseToTitle(leaseInputs?.applicants.map(obj => obj.email).join(', '))}
                                            // handleChange={(value) => handleChange('applicants', key, value)}
                                            disabled={true}
                                        />  
                                    </WrapDiv>  
                                }
                                {
                                    leaseInputs?.applicants &&
                                    <WrapDiv>
                                        <FormInput
                                            label = "Phone Numbers"
                                            type = "text"
                                            isRequired={true}
                                            flexBasis={true}
                                            defaultValue={camelCaseToTitle(leaseInputs?.applicants.map(obj => obj.contact).join(', '))}
                                            // handleChange={(value) => handleChange('applicants', key, value)}
                                            disabled={true}
                                        />  
                                    </WrapDiv>  
                                }
                            </Section>
                            {/* <Section>
                                <SectionTitle>Maintenance Information</SectionTitle>
                                    <Label>
                                        <input
                                            name = "likeLandlord"
                                            type = "checkbox"
                                            onChange={toggleSameAsLandlord}
                                            checked={sameAsLandlord}
                                        />Same as landlord?
                                    </Label>
                                <WrapDiv>
                                    {
                                        Object.entries(leaseInputs.maintenance)?.map(([key, value]) =>
                                            <FormInput
                                                label = {camelCaseToTitle(key)}
                                                type = "text"
                                                isRequired={true}
                                                flexBasis={true}
                                                defaultValue={value}
                                                handleChange={(value) => handleChange('maintenance', key, value)}
                                                disabled={sameAsLandlord}
                                            />    
                                        )
                                    }
                                </WrapDiv>
                            </Section> */}
                            <Section>
                                <SectionTitle>Standard Provisions</SectionTitle>
                                <TextInput
                                    rows={5}
                                    value={leaseInputs?.standardProvisions}
                                    onChange={(event) => handleChange(null, 'standardProvisions', event.target.value)}
                                />
                            </Section>
                            <Section>
                                <SectionTitle>Other Provisions</SectionTitle>
                                <TextInput
                                    rows={5}
                                    value={leaseInputs?.otherProvisions}
                                    onChange={(event) => handleChange(null, 'otherProvisions', event.target.value)}
                                />
                            </Section>
                            <Section>
                                <SectionTitle>Rent & Deposit Information</SectionTitle>
                                <PaymentContainer>
                                    <PaymentTitle>
                                        <div>Rent Type</div>
                                        <div>Due Date</div>
                                        <div>Amount</div>
                                    </PaymentTitle>
                                    {
                                        leaseInputs?.deposits &&
                                        Object.entries(leaseInputs.deposits)?.filter(([key,_]) => key !=="Application Fee").map(([key,value]) => (
                                            <Payment key = {key}>
                                                <div>{key} {key === leaseInputs.depositPaidType && <span>Paid</span>}</div>
                                                {
                                                    !(key === leaseInputs.depositPaidType) ?
                                                    <input
                                                        type="date" 
                                                        id={`dueDate-${key}`} 
                                                        name={`Due Date`}
                                                        value={moment(new Date(value.dueDate)).format('YYYY-MM-DD')}
                                                        disabled
                                                        // onChange={(e) => handleDateChange(key,e.target.value)}
                                                    />
                                                    :
                                                    <></>
                                                }
                                                <div>{formatCurrency(value.amount)}</div>
                                            </Payment>
                                        ))
                                    }
                                </PaymentContainer>
                            </Section>
                            <Section>
                                <SectionTitle>Lead Certification</SectionTitle>
                                <FlexColumnDiv>
                                    <Heading>Presence of lead paint or lead based paint hazards</Heading>
                                    <Label>
                                        <input
                                            name = "leadPresence"
                                            type = "radio"
                                            value = {true}
                                            checked={leaseInputs.tenantCertification.leadPresence === true}
                                            onChange={() => handleChange('tenantCertification', "leadPresence", true)}
                                            required
                                        />Known lead paint or lead based paints hazards are present in the housing
                                    </Label>
                                    <Label>
                                        <input
                                            name = "leadPresence"
                                            type = "radio"
                                            value = {false}
                                            checked={leaseInputs.tenantCertification.leadPresence === false}
                                            onChange={() => handleChange('tenantCertification', "leadPresence", false)}
                                            required
                                        />Owner/Lessor has no knowledge of lead paint or lead based paints hazards in the housing
                                    </Label>
                                </FlexColumnDiv>
                                <FlexColumnDiv>
                                    <Heading>Records and reports are available to the Owner/Lessor</Heading>
                                    <Label>
                                        <input
                                            name = "recordsAvailable"
                                            type = "radio"
                                            value = {true}
                                            checked={leaseInputs.tenantCertification.recordsAvailable === true}
                                            onChange={() => handleChange('tenantCertification', "recordsAvailable", true)}
                                            required
                                        />Owner/Lessor has provided the tenant with all available records and reports pertaining to lead paint or lead based paints hazards in the housing
                                    </Label>
                                    <Label>
                                        <input
                                            name = "recordsAvailable"
                                            type = "radio"
                                            value = {false}
                                            checked={leaseInputs.tenantCertification.recordsAvailable === false}
                                            onChange={() => handleChange('tenantCertification', "recordsAvailable", false)}
                                            required
                                        />Owner/Lessor has no reports or records pertaining to lead paint or lead based paints hazards in the housing
                                    </Label>
                                </FlexColumnDiv>
                                <FlexColumnDiv>
                                    <Heading>Lead Paint Addendum Acknowledgement</Heading>
                                    <Label>
                                        <input
                                            name = "tenantAcknowledgement"
                                            type = "radio"
                                            value = "No Documents"
                                            checked={leaseInputs.tenantCertification.tenantAcknowledgement === "No Documents"}
                                            onChange={() => handleChange('tenantCertification', "tenantAcknowledgement", "No Documents")} 
                                            required
                                        />Tenants have received no docs listed regarding lead paint or lead based paint hazard
                                    </Label>
                                    <Label>
                                        <input
                                            name = "tenantAcknowledgement"
                                            type = "radio"
                                            value = "All Documents"
                                            checked={leaseInputs.tenantCertification.tenantAcknowledgement === "All Documents"}
                                            onChange={() => handleChange('tenantCertification', "tenantAcknowledgement", "All Documents")}
                                            required
                                        />Tenants have received copies of all docs 
                                    </Label>
                                    <Label>
                                        <input
                                            name = "tenantAcknowledgement"
                                            type = "radio"
                                            value = "MA Lead Law Notification"
                                            checked={leaseInputs.tenantCertification.tenantAcknowledgement === "MA Lead Law Notification"}
                                            onChange={() => handleChange('tenantCertification', "tenantAcknowledgement", "MA Lead Law Notification")}
                                            required
                                        />Tenants have received the Massachusetts lead law notification
                                    </Label>
                                </FlexColumnDiv>
                            </Section>
                            <Section>
                                {
                                    user.clientId === "star_realty" ?
                                    <>
                                        <SectionTitle>Sight Unseen Addendum</SectionTitle>
                                        <FlexColumnDiv>
                                            <Heading>Should the lease have a sight unseen clause?</Heading>
                                            <Label>
                                                <input
                                                    name = "sightUnseenAddendum"
                                                    type = "radio"
                                                    value = {true}
                                                    checked={leaseInputs?.sightUnseenAddendum === true}
                                                    onChange={() => handleChange(null, "sightUnseenAddendum", true)}
                                                    required
                                                />Yes
                                            </Label>
                                            <Label>
                                                <input
                                                    name = "sightUnseenAddendum"
                                                    type = "radio"
                                                    value = {false}
                                                    checked={leaseInputs?.sightUnseenAddendum === false}
                                                    onChange={() => handleChange(null, "sightUnseenAddendum", false)}
                                                    required
                                                />No
                                            </Label>
                                        </FlexColumnDiv>
                                    </>
                                    :
                                    <>
                                        <SectionTitle>Water & Sewer Payments</SectionTitle>
                                        <FlexColumnDiv>
                                            <Heading>Is the tenant responsible for paying Water & Sewer?</Heading>
                                            <Label>
                                                <input
                                                    name = "waterAndSewerAddendum"
                                                    type = "radio"
                                                    value = {true}
                                                    checked={leaseInputs?.waterAndSewerAddendum === true}
                                                    onChange={() => handleChange(null, "waterAndSewerAddendum", true)}
                                                    required
                                                />Yes
                                            </Label>
                                            <Label>
                                                <input
                                                    name = "waterAndSewerAddendum"
                                                    type = "radio"
                                                    value = {false}
                                                    checked={leaseInputs?.waterAndSewerAddendum === false}
                                                    onChange={() => handleChange(null, "waterAndSewerAddendum", false)}
                                                    required
                                                />No
                                            </Label>
                                        </FlexColumnDiv>
                                    </>
                                }
                                <Section>
                                    <SectionTitle>Tax Documentation</SectionTitle>
                                    <FlexColumnDiv>
                                        <Heading>Which applicant do you want to send w8/w9 tax form to?</Heading>
                                        {
                                            leaseInputs?.applicants &&
                                            leaseInputs.applicants.map(applicant =>
                                                <Label>
                                                    <input
                                                        name = "taxApplicant"
                                                        type = "radio"
                                                        value = {applicant.id}
                                                        checked={leaseInputs?.taxApplicant === applicant.id}
                                                        onChange={() => handleChange(null, "taxApplicant", applicant.id)}
                                                        required
                                                    />{applicant.name}  ({applicant.type})
                                                </Label>
                                            )
                                        }
                                    </FlexColumnDiv>
                                    <FlexColumnDiv>
                                        <Heading>Which document should they be served?</Heading>
                                        <Label>
                                                <input
                                                    name = "taxDocument"
                                                    type = "radio"
                                                    value = 'w8'
                                                    checked={leaseInputs?.taxDocument === 'w8'}
                                                    onChange={() => handleChange(null, "taxDocument", 'w8')}
                                                    required
                                                />w8
                                            </Label>
                                            <Label>
                                                <input
                                                    name = "taxDocument"
                                                    type = "radio"
                                                    value = 'w9'
                                                    checked={leaseInputs?.taxDocument === 'w9'}
                                                    onChange={() => handleChange(null, "taxDocument", 'w9')}
                                                    required
                                                />w9
                                        </Label>
                                    </FlexColumnDiv>
                                </Section>
                            </Section>
                            <SubmitButton>Preview Lease</SubmitButton>
                        </FormContainer>
                    </>
                    :
                    <>
                        <SpaceDiv>
                            <Title>Preview Lease Agreement</Title>
                            <FlexDiv>
                                <span style={{fontSize:"14px"}}>Close</span>
                                <CloseButton onClick={onClose}>X</CloseButton>
                            </FlexDiv>
                        </SpaceDiv>
                        <iframe 
                            style={{height:'100%',width:'100%', border:'1px solid #323232', borderRadius:'10px'}}  
                            src={window.location.hostname === 'localhost'? 
                                `http://localhost:8000/media/${previewData.leaseDoc}`:`${window.location.origin}/media/${previewData.leaseDoc}`
                            }
                        />
                        {
                            showSubmit &&
                            <FlexDiv>
                                <PreviousButton onClick={() => setIsFocus('Inputs')}>Previous</PreviousButton>
                                <SubmitButton onClick={handleSendLease}>Send Lease for Signing</SubmitButton>
                            </FlexDiv>
                        }
                    </>
                }
                
            </ModalWrapper>
        </ModalOverlay>
    )
}

export default CreateLeaseModality