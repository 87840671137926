import React, { useEffect, useState, useContext, useRef } from "react";
import ShowingCard from "../../components/showing-card/showing-card";
import ShowingModal from "../../components/showing-modality/showing-modality.jsx";
import ShowingFilter from "../../components/showing-filter/showing-filter.jsx";
import { ApiContext } from "../../context/apiContext.js";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LoaderCard from "../../components/loading-cards/loading-card.jsx";
import { ContentSection } from "./showings.pages.styles.js";

const PAGE_SIZE = 10;

const Showings = ({ headerHeight }) => {
  const api = useContext(ApiContext);
  const { showingId } = useParams();

  // States
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("info");
  const [focusId, setFocusId] = useState(null);
  const [showingData, setShowingData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [forceFetch, setForceFetch] = useState(false);
  const isFirstRender = useRef(true);

  // For filtering and ordering
  const [sortField, setSortField] = useState("");

  const [filterFields, setFilterFields] = useState({
    status: "",
    property: "",
    agent: "",
  });

  // Ref for observer (Pagination)
  const observerRef = useRef();

  // Helper Functions
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFocusId(null);
    window.history.pushState({}, "", `/showings`);
  };

  const handleShowingClick = (id) => {
    setFocusId(id);
    setIsModalOpen(true);
    window.history.pushState({}, "", `/showings/${id}`);
  };

  const fetchData = async (pageNumber) => {
    try {
      let apiURL = `/api/showing/internal/all?page=${pageNumber}&size=${PAGE_SIZE}`;
      if (filterFields.status) apiURL += `&status=${filterFields.status}`;
      if (filterFields.property) {
        apiURL += `&listing_id=${encodeURIComponent(filterFields.property)}`;
      }
      if (filterFields.agent) apiURL += `&agent_id=${filterFields.agent}`;
      if (sortField && sortField !== "") apiURL += `&ordering=${sortField}`;
      const response = await api.get(apiURL);

      if (pageNumber === 1) {
        setShowingData(response.results);
      } else {
        setShowingData((prev) => [...prev, ...response.results]);
      }

      if (response.next) setHasMore(true);
      else setHasMore(false);
    } catch (error) {
      setHasMore(false);
      toast.error(`Error loading data: ${error.message}`);
    } finally {
      setIsLoading(false);
      if (forceFetch) setForceFetch(false);
    }
  };

  const updateShowing = (updateData) => {
    setShowingData((prev) =>
      prev.map((showing) =>
        showing.id === updateData.id ? { ...showing, ...updateData } : showing
      )
    );
    setIsModalOpen(false);
    toast.success(
      `The status of the showing has been changed to ${updateData.status}`
    );
  };

  // Initial Fetch
  useEffect(() => {
    fetchData(1);
  }, []);

  // Trigger modal if `showingId` is present in URL
  useEffect(() => {
    if (showingId) {
      handleShowingClick(showingId);
    }
  }, [showingId]);

  // Infinite Scrolling
  useEffect(() => {
    const loadMoreEntries = (entries) => {
      const target = entries[0];
      if (target.isIntersecting && hasMore) {
        setPage((prev) => prev + 1);
      }
    };

    const observer = new IntersectionObserver(loadMoreEntries, {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    });

    if (observerRef.current) observer.observe(observerRef.current);

    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current);
    };
  }, [hasMore]);

  // Fetch data when `filterFields` or `sortField` changes, reset `page`
  useEffect(() => {
    // Prevents the API calling again on first render
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    setPage(1);
    setForceFetch(true);
  }, [filterFields, sortField]);

  // Fetch data when `page` changes
  useEffect(() => {
    if (page > 1 || forceFetch) fetchData(page);
  }, [page, forceFetch]);

  return (
    <>
      <ShowingFilter
        filterFields={filterFields}
        setFilterFields={setFilterFields}
        setSortField={setSortField}
      />
      <ContentSection headerHeight={headerHeight}>
        {isLoading ? (
          <LoaderCard />
        ) : (
          showingData.map((showingRequest) => (
            <ShowingCard
              key={showingRequest.id}
              showingRequest={showingRequest}
              setActiveTab={setActiveTab}
              toggleModal={() => handleShowingClick(showingRequest.id)}
            />
          ))
        )}
        <div ref={observerRef} style={{ height: "1px" }} />
      </ContentSection>
      {isModalOpen && (
        <ShowingModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          focusId={focusId}
          setFocusId={setFocusId}
          showingDataIds={showingData.map((data) => data.id)}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          updateShowing={updateShowing}
        />
      )}
    </>
  );
};

export default Showings;
