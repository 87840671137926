import React, { useContext, useEffect, useState, useRef } from "react";
import ApplicationsFilter from "../../components/applications-filter/applications-filter.jsx";
import ApplicationCard from "../../components/application-card/application-card";
import ApplicationModal from "../../components/application-modality/application-modality.jsx";
import LoaderCard from "../../components/loading-cards/loading-card.jsx";
import { toast } from 'react-toastify'
import { ApiContext } from "../../context/apiContext.js";
import { useNavigate, useParams } from "react-router-dom";
import {
    ContentSection
} from './applications.pages.styles'

const PAGE_SIZE = 10;

const Applications = ({ headerHeight }) => {
    const api = useContext(ApiContext);
    const [applications, setApplications] = useState([])
    const [query, setQuery] = useState("&status__in=New,Incomplete"); // New Tab
    const [focusId, setFocusId] = useState()
    const [activeTab, setActiveTab] = useState('Review')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const { applicationId } = useParams();

    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [forceFetch, setForceFetch] = useState(false);

    // Ref for observer (Pagination)
    const observerRef = useRef();

    const fetchData = async (pageNumber) => {
        try {

            let apiURL = `/api/application/all?page=${pageNumber}&size=${PAGE_SIZE}`;
            if (query) {
                apiURL += query;
            }
            const response = await api.get(apiURL);
            if (applicationId) {
                handleApplicationClick(applicationId)
            }
            if (pageNumber === 1) {
                setApplications(response.results);
            } else {
                setApplications((prev) => [...prev, ...response.results]);
            }
            if (response.next) setHasMore(true);
            else setHasMore(false);
        } catch (error) {
            // The catch block now handles any errors thrown from the API request
            toast.error(`There was an error loading the data: ${error.message}`);
            setHasMore(false);
        }
        finally {
            setIsLoading(false);
            if (forceFetch) setForceFetch(false);
        }
    };


    useEffect(() => {
        if (page > 1 || forceFetch) fetchData(page);
    }, [page, forceFetch]);

    useEffect(() => {
        setPage(1);
        setForceFetch(true);
    }, [query]);

    useEffect(() => {
        const loadMoreEntries = (entries) => {
            const target = entries[0];
            if (target.isIntersecting && hasMore) {
                setPage((prev) => prev + 1);
            }
        };

        const observer = new IntersectionObserver(loadMoreEntries, {
            root: null,
            rootMargin: "20px",
            threshold: 1.0,
        });

        if (observerRef.current) observer.observe(observerRef.current);

        return () => {
            if (observerRef.current) observer.unobserve(observerRef.current);
        };
    }, [hasMore, query]);


    const handleApplicationClick = (id) => {
        setFocusId(id);
        setIsModalOpen(true); // Directly open the modal here
        window.history.pushState({}, '', `/applications/${id}`);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setFocusId(undefined); // Reset focusId when modal closes
        window.history.pushState({}, '', `/applications`);
    };

    const updateApplication = (updateData) => {
        setApplications(currentApplications => currentApplications.map(app =>
            app.id == updateData.applicationId ? { ...app, ...updateData } : app
        ));
        setApplications(currentApplications => currentApplications.map(app =>
            app.id == updateData.applicationId ? { ...app, ...updateData } : app
        ));
        setIsModalOpen(false);
        toast(`Application status changed to ${updateData.status}`, {
            onClick: () => navigate(`applications/${updateData.applicationId}`)
        })
    }

    // Optimized handleFilter function

    const handleFilter = (value) => {
        switch (value) {
            case 'Pending':
                setQuery("&status=Pending");
                break;
            case 'New':
                setQuery("&status__in=New,Incomplete");
                break
            case 'Under Review': 
                setQuery("&status=New&assigned_to_me=true"); 
                break;
            case 'Assigned To Me':
                setQuery("&status__neq=Approved&is_holding_deposit_paid=true&assigned_to_me=true");
                break;
            case 'Deposit Pending':
                setQuery("&status=Approved&is_holding_deposit_paid=false");
                break;
            case 'All':
                setQuery("&status__neq=Pending");
                break;
            default:
                setQuery(null);
        }
    };


    return (
        <>
            <ApplicationsFilter
                handleFilter={handleFilter}
            />
            <ContentSection headerHeight={headerHeight}>
                {
                    isLoading ?
                        <LoaderCard />
                        :
                        (applications?.map(application =>
                            <ApplicationCard
                                key={application.id}
                                application={application}
                                setActiveTab={setActiveTab}
                                toggleModal={() => handleApplicationClick(application.id)}
                                setFocusApplication={() => setFocusId(application.id)}
                                updateApplication={updateApplication}
                            />
                        ))
                }
                <div ref={observerRef} style={{ height: "1px" }}/>
            </ContentSection>
            {
                isModalOpen &&
                <ApplicationModal
                    applicationId={focusId}
                    onClose={handleCloseModal}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    updateApplication={updateApplication}
                />
            }
        </>
    )
}

export default Applications