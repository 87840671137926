import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { ApiContext } from "../../context/apiContext";
import { UserContext } from "../../context/userContext";
import {
  FilterBarContainer,
  FilterGroup,
  Dropdown,
  ButtonDiv,
  Title,
  SpaceDiv,
  Label,
  FilterDiv,
  SortList,
  FilterLabel,
  SortContainer,
  SortItem,
  ClearButton,
} from "./showing-filter.styles";

import { ReactComponent as Filter } from "../../assets/Filter.svg";
import { ReactComponent as FilterBlue } from "../../assets/Filter-blue.svg";

import { ReactComponent as Sort } from "../../assets/sort.svg";
import { ReactComponent as Down } from "../../assets/down-arrow.svg";

import { toast } from "react-toastify";
import SearchDropdown from "../utils/SearchDropdown";

const ShowingFilter = ({ filterFields, setFilterFields, setSortField }) => {
  const api = useContext(ApiContext);
  const { user } = useContext(UserContext);
  const [isFilter, setIsFilter] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);

  const [reset, setReset] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Sort by");

  const [autoConfirm, setAutoConfirm] = useState(false);

  const sortRef = useRef(); // Ref for the sort dropdown

  // Effect to get the auto-confirm setting on mount
  useEffect(() => {
    const getAutoConfirm = async () => {
      try {
        const data = await api.get("/api/settings/autoconfirm");
        const newSetting =
          data.auto_confirm_showings === "enabled" ? true : false;
        setAutoConfirm(newSetting);
      } catch (error) {
        // Optionally handle the error (e.g., set a state or show a message)
      }
    };
    getAutoConfirm();
  }, []);

  const fetchOptionsData = useCallback(
    async (field, query) => {
      const params = new URLSearchParams();

      if (filterFields.status !== "") {
        params.append("status", filterFields.status);
      }
      if (filterFields.property !== "") {
        params.append("listing_id", filterFields.property);
      }
      if (filterFields.agent !== "") {
        params.append("agent_id", filterFields.agent);
      }

      params.append("search_field", field === "property" ? "listing" : field);
      params.append("query", query);

      const apiURL = `/api/showing/search${
        params.toString() ? "?" + params.toString() : ""
      }`;
      try {
        const response = await api.get(apiURL);
        return response;
      } catch (error) {
        toast.error(`Error loading data: ${error.message}`);
      }
    },
    [filterFields]
  );

  const handleOptionClick = (option) => {
    // let sortedData;
    switch (option) {
      case "Status":
        setSortField("status");
        break;
      case "Agent":
        setSortField("agent_id");
        break;
      // case 'Requested Date':
      //     sortedData = [...filteredData].sort(sortByRequestedDate);
      //     break;
      case "Property":
        setSortField("listing_id");
        break;
      default:
        setSortField("");
    }
    setSelectedOption(option);
    setIsSortOpen(false);
  };

  // Handler for when the checkbox changes
  const handleAutoConfirmChange = async (event) => {
    const status = event.target.checked;
    const newSetting = status ? "enabled" : "disabled";
    try {
      const response = await api.post("/api/settings/update-autoconfirm", {
        auto_confirm_showings: newSetting,
      });
      if (response) {
        // Assuming api.post handles the response.ok internally
        setAutoConfirm(status);
      } else {
        // Handle other status codes or issues within your API client
        throw new Error("Failed to update setting");
      }
    } catch (error) {
      alert(
        "Could not change auto confirm at this time. Please try again later"
      );
    }
  };

  useEffect(() => {
    if (reset) {
      setFilterFields({
        status: "",
        property: "",
        agent: "",
      });
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (sortRef.current && !sortRef.current.contains(event.target)) {
        setIsSortOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sortRef, setIsSortOpen]);

  const handleSelect = (field, item) => {
    if (field === "status") {
      setFilterFields((prev) => ({ ...prev, status: item.value }));
    } else if (field === "property") {
      setFilterFields((prev) => ({ ...prev, property: item.id }));
    } else if (field === "agent") {
      setFilterFields((prev) => ({ ...prev, agent: item.id }));
    }
  };

  return (
    <FilterBarContainer>
      <SpaceDiv>
        <Title>Showings</Title>
        <FilterGroup>
          <ButtonDiv
            isFilter={
              isFilter ||
              filterFields.status !== "" ||
              filterFields.property !== "" ||
              filterFields.agent !== ""
            }
            onClick={() => setIsFilter(!isFilter)}
          >
            {isFilter ||
            filterFields.status !== "" ||
            filterFields.property !== "" ||
            filterFields.agent !== "" ? (
              <FilterBlue />
            ) : (
              <Filter />
            )}
            Filter By
          </ButtonDiv>
          <SortContainer>
            <ButtonDiv onClick={() => setIsSortOpen(!isSortOpen)}>
              <Sort />
              {selectedOption} <Down />
            </ButtonDiv>
            {isSortOpen && (
              <SortList ref={sortRef}>
                {selectedOption !== "Sort by" && (
                  <SortItem onClick={() => handleOptionClick("Sort by")}>
                    <strong>Clear</strong>
                  </SortItem>
                )}
                {selectedOption !== "Status" && (
                  <SortItem onClick={() => handleOptionClick("Status")}>
                    Status
                  </SortItem>
                )}
                {selectedOption !== "Property" && (
                  <SortItem onClick={() => handleOptionClick("Property")}>
                    Property
                  </SortItem>
                )}
                {selectedOption !== "Agent" && (
                  <SortItem onClick={() => handleOptionClick("Agent")}>
                    Agent
                  </SortItem>
                )}
                {selectedOption !== "Requested Date" && (
                  <SortItem onClick={() => handleOptionClick("Requested Date")}>
                    Requested Date
                  </SortItem>
                )}
              </SortList>
            )}
          </SortContainer>
          {user?.userType === "admin" && user?.autoconfirmFeatureEnabled && (
            <Label>
              <input
                type="checkbox"
                label="Auto Confirm"
                name="Auto Confirm"
                checked={autoConfirm} // Controlled component
                onChange={handleAutoConfirmChange} // Handle changes
              />
              Auto Confirm
            </Label>
          )}
        </FilterGroup>
      </SpaceDiv>
      {isFilter && (
        <FilterGroup>
          <FilterDiv>
            <FilterLabel>Status:</FilterLabel>
            <SearchDropdown
              key={1}
              field="status"
              placeholder="Search Status"
              onSelect={(item) => handleSelect("status", item)}
              onClose={() => {
                if (filterFields.status !== "") {
                  setFilterFields((prev) => ({ ...prev, status: "" }));
                }
              }}
              noOptionsMessage="No status found"
              fetchData={fetchOptionsData}
              reset={reset}
            />
          </FilterDiv>
          <FilterDiv>
            <FilterLabel>Property:</FilterLabel>
            <SearchDropdown
              key={2}
              field="property"
              placeholder="Search Property"
              onSelect={(item) => handleSelect("property", item)}
              onClose={() => {
                if (filterFields.property !== "") {
                  setFilterFields((prev) => ({ ...prev, property: "" }));
                }
              }}
              noOptionsMessage="No properties found"
              fetchData={fetchOptionsData}
              reset={reset}
            />
          </FilterDiv>
          <FilterDiv>
            <FilterLabel>Agent:</FilterLabel>
            <SearchDropdown
              key={3}
              field="agent"
              placeholder="Search agents"
              onSelect={(item) => handleSelect("agent", item)}
              onClose={() => {
                if (filterFields.agent !== "") {
                  setFilterFields((prev) => ({ ...prev, agent: "" }));
                }
              }}
              noOptionsMessage="No agents found"
              fetchData={fetchOptionsData}
              reset={reset}
            />
          </FilterDiv>
          <ClearButton onClick={() => setReset(true)}>
            Clear Filters
          </ClearButton>
        </FilterGroup>
      )}
    </FilterBarContainer>
  );
};

export default ShowingFilter;
