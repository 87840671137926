// import React, { useState, useEffect, useContext } from "react";

import { ModalOverlay, ModalWrapper } from "./applicant-modality.styles.js";
import BaseApplicantForm from "./applicant-form/base-applicant-form.jsx";
import {X} from 'lucide-react';

const ApplicantModal = ({
  applicant,
  applicantDocuments,
  setApplicant,
  setApplicantDocuments,
  onClose,
  handleSave,
  isOpen,
  mode,
  applicationPackageState
}) => {
  return (
    <ModalOverlay onClick={onClose}>
      <ModalWrapper isOpen={isOpen} onClick={(e) => e.stopPropagation()}>
        <div className="p-2 cursor-pointer"><X strokeWidth={2} onClick={onClose}/></div>
        <BaseApplicantForm
          applicant={applicant}
          applicantDocuments={applicantDocuments}
          setApplicant={setApplicant}
          setApplicantDocuments={setApplicantDocuments}
          handleSave={handleSave}
          mode = {mode}
          applicationPackageState = {applicationPackageState}
        />
      </ModalWrapper>
    </ModalOverlay>
  );
};

export default ApplicantModal;
