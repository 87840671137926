import React, { createContext, useCallback, useEffect, useRef, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    
    // Convert state to refs
    const userRef = useRef(null);
    const accessTokenRef = useRef(localStorage.getItem('accessToken'));
    const refreshTokenRef = useRef(localStorage.getItem('refreshToken'));

    // Getter and setter functions for refs
    const getUser = () => userRef.current;
    const setUser = (newUser) => {
        userRef.current = newUser;
    };

    const getAccessToken = () => accessTokenRef.current;
    const setAccessToken = (token) => {
        accessTokenRef.current = token;
    };

    const getRefreshToken = () => refreshTokenRef.current;
    const setRefreshToken = (token) => {
        refreshTokenRef.current = token;
    };

    const login = useCallback(async (username, password) => {
        try {
            const response = await fetch('/api/user/token/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            if (!response.ok) {
                throw new Error('Login failed');
            }

            const data = await response.json();
            setUser(data);
            setAccessToken(data.access);
            setRefreshToken(data.refresh);

            localStorage.setItem('accessToken', data.access);
            localStorage.setItem('refreshToken', data.refresh);

            if(data.userType === "landlord"){
                navigate('/leases');
            } else {
                navigate('/showings');
            }
        } catch (error) {
            return false;
        }
    }, [navigate]);

    const logout = useCallback(() => {
        setUser(null);
        setAccessToken(null);
        setRefreshToken(null);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        navigate('/');
    }, [navigate]);

    const refreshTokens = useCallback(async () => {
        try {
            const response = await fetch('/api/user/token/refresh/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ refresh: getRefreshToken() }),
            });

            if (!response.ok) {
                logout();
                return;
            }

            const data = await response.json();
            setAccessToken(data.access);
            setRefreshToken(data.refresh);
            localStorage.setItem('accessToken', data.access);
            localStorage.setItem('refreshToken', data.refresh);
        } catch (error) {
            toast.error(`Refresh token error: ${error}`);
            logout();
        }
    }, [logout]);

    const verifyToken = useCallback(async () => {
        const currentAccessToken = getAccessToken();
        if (!currentAccessToken) {
            navigate('/');
            return;
        }

        try {
            const response = await fetch('/api/user/profile_details/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${currentAccessToken}`
                }
            });
            
            if (response.ok) {
                const userData = await response.json();
                setUser(userData);

                if (location.pathname === '/') {
                    if(userData.userType === "landlord") {
                        navigate('/leases');
                    } else {
                        navigate('/showings');
                    }
                }
                return true;
            } else {
                logout();
            }
        } catch (error) {
            toast.error(`Error verifying token: ${error}`);
        }
    }, [navigate, location.pathname, logout]);

    const resetPassword = useCallback(async (data) => {
        try {
            const response = await fetch('/api/user/reset_password/', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getAccessToken()}`
                },
                body: JSON.stringify(data)
            });
            
            if (response.ok) {
                const userData = await response.json();
                setUser(userData);
                toast.success('The password has successfully been changed');
                return true;
            } else {
                throw new Error('Could not reset password');
            }
        } catch (error) {
            toast.error(`Error verifying token: ${error}`);
        }
    }, []);

    // Memoize the context value
    const value = useMemo(() => ({
        user: getUser(),
        accessToken: getAccessToken(),
        login,
        refreshTokens,
        verifyToken,
        resetPassword,
        logout
    }), [login, refreshTokens, verifyToken, resetPassword, logout]);

    useEffect(() => {
        const interval = setInterval(refreshTokens, 1000 * 60 * 60);
        return () => clearInterval(interval);
    }, [refreshTokens]);

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
};