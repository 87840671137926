import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import {
  FilterBarContainer,
  FilterGroup,
  ButtonDiv,
  Title,
  SpaceDiv,
  FilterDiv,
  SortList,
  FilterLabel,
  SortContainer,
  SortItem,
  ClearButton,
} from "./deals-header.styles";

import { ReactComponent as Filter } from "../../assets/Filter.svg";
import { ReactComponent as FilterBlue } from "../../assets/Filter-blue.svg";

import { ReactComponent as Sort } from "../../assets/sort.svg";
import { ReactComponent as Down } from "../../assets/down-arrow.svg";
import { ApiContext } from "../../context/apiContext";

import { toast } from "react-toastify";
import SearchDropdown from "../utils/SearchDropdown";

const DealsHeader = ({ filterFields, setFilterFields, setSortField }) => {
  const api = useContext(ApiContext);

  const [isFilter, setIsFilter] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Sort by");

  const [reset, setReset] = useState(false);

  const sortRef = useRef();

  const fetchOptionsData = useCallback(
    async (field, query) => {
      const params = new URLSearchParams();

      if (filterFields.payment_status !== "") {
        params.append("payment_status", filterFields.payment_status);
      }
      if (filterFields.lease_status !== "") {
        params.append("lease_status", filterFields.lease_status);
      }
      if (filterFields.property !== "") {
        params.append("listing_id", filterFields.property);
      }
      if (filterFields.agent !== "") {
        params.append("agent_id", filterFields.agent);
      }

      params.append("search_field", field === "property" ? "listing" : field);
      params.append("query", query);

      const apiURL = `/api/deal/search${
        params.toString() ? "?" + params.toString() : ""
      }`;
      try {
        const response = await api.get(apiURL);
        return response;
      } catch (error) {
        toast.error(`Error loading data: ${error.message}`);
      }
    },
    [filterFields]
  );

  const handleSelect = (field, item) => {
    if (field === "payment_status") {
      setFilterFields((prev) => ({ ...prev, payment_status: item.value }));
    } else if (field === "lease_status") {
      setFilterFields((prev) => ({ ...prev, lease_status: item.value }));
    } else if (field === "property") {
      setFilterFields((prev) => ({ ...prev, property: item.id }));
    } else if (field === "agent") {
      setFilterFields((prev) => ({ ...prev, agent: item.id }));
    }
  };

  const handleOptionClick = (option) => {
    switch (option) {
      case "Lease Status":
        setSortField("lease_status");
        break;
      case "Payment Status":
        setSortField("payment_status");
        break;
      case "Agent":
        setSortField("agent_id");
        break;
      case "Property":
        setSortField("listing_id");
        break;
      default:
        setSortField("");
    }
    setSelectedOption(option);
    setIsSortOpen(false);
  };

  useEffect(() => {
    if (reset) {
      setFilterFields({
        payment_status: "",
        lease_status: "",
        property: "",
        agent: "",
      });
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (sortRef.current && !sortRef.current.contains(event.target)) {
        setIsSortOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sortRef, setIsSortOpen]);

  return (
    <FilterBarContainer>
      <SpaceDiv>
        <Title>All Deals</Title>
        <FilterGroup>
          <ButtonDiv
            isFilter={
              isFilter ||
              filterFields.payment_status !== "" ||
              filterFields.lease_status !== "" ||
              filterFields.property !== "" ||
              filterFields.agent !== ""
            }
            onClick={() => setIsFilter(!isFilter)}
          >
            {isFilter ||
            filterFields.payment_status !== "" ||
            filterFields.lease_status !== "" ||
            filterFields.property !== "" ||
            filterFields.agent !== "" ? (
              <FilterBlue />
            ) : (
              <Filter />
            )}
            Filter By
          </ButtonDiv>
          <SortContainer>
            <ButtonDiv onClick={() => setIsSortOpen(!isSortOpen)}>
              <Sort />
              {selectedOption} <Down />
            </ButtonDiv>
            {isSortOpen && (
              <SortList ref={sortRef}>
                {selectedOption !== "Sort by" && (
                  <SortItem onClick={() => handleOptionClick("Sort by")}>
                    <strong>Clear</strong>
                  </SortItem>
                )}
                {selectedOption !== "Payment Status" && (
                  <SortItem onClick={() => handleOptionClick("Payment Status")}>
                    Payment Status
                  </SortItem>
                )}
                {selectedOption !== "Lease Status" && (
                  <SortItem onClick={() => handleOptionClick("Lease Status")}>
                    Lease Status
                  </SortItem>
                )}
                {selectedOption !== "Property" && (
                  <SortItem onClick={() => handleOptionClick("Property")}>
                    Property
                  </SortItem>
                )}
                {selectedOption !== "Agent" && (
                  <SortItem onClick={() => handleOptionClick("Agent")}>
                    Agent
                  </SortItem>
                )}
              </SortList>
            )}
          </SortContainer>
        </FilterGroup>
      </SpaceDiv>
      {isFilter && (
        <FilterGroup>
          <FilterDiv>
            <FilterLabel>Payment Status:</FilterLabel>
            <SearchDropdown
              key={1}
              field="payment_status"
              placeholder="Search Payment Status"
              onSelect={(item) => handleSelect("payment_status", item)}
              onClose={() => {
                if (filterFields.payment_status !== "") {
                  setFilterFields((prev) => ({ ...prev, payment_status: "" }));
                }
              }}
              noOptionsMessage="No payment status found"
              fetchData={fetchOptionsData}
              reset={reset}
            />
          </FilterDiv>
          <FilterDiv>
            <FilterLabel>Lease Status:</FilterLabel>
            <SearchDropdown
              key={1}
              field="lease_status"
              placeholder="Search Lease Status"
              onSelect={(item) => handleSelect("lease_status", item)}
              onClose={() => {
                if (filterFields.lease_status !== "") {
                  setFilterFields((prev) => ({ ...prev, lease_status: "" }));
                }
              }}
              noOptionsMessage="No Lease status found"
              fetchData={fetchOptionsData}
              reset={reset}
            />
          </FilterDiv>
          <FilterDiv>
            <FilterLabel>Property:</FilterLabel>
            <SearchDropdown
              key={3}
              field="property"
              placeholder="Search Property"
              onSelect={(item) => handleSelect("property", item)}
              onClose={() => {
                if (filterFields.property !== "") {
                  setFilterFields((prev) => ({ ...prev, property: "" }));
                }
              }}
              noOptionsMessage="No properties found"
              fetchData={fetchOptionsData}
              reset={reset}
            />
          </FilterDiv>
          <FilterDiv>
            <FilterLabel>Agent:</FilterLabel>
            <SearchDropdown
              key={4}
              field="agent"
              placeholder="Search agents"
              onSelect={(item) => handleSelect("agent", item)}
              onClose={() => {
                if (filterFields.agent !== "") {
                  setFilterFields((prev) => ({ ...prev, agent: "" }));
                }
              }}
              noOptionsMessage="No agents found"
              fetchData={fetchOptionsData}
              reset={reset}
            />
          </FilterDiv>
          <ClearButton onClick={() => setReset(true)}>Clear Filters</ClearButton>
        </FilterGroup>
      )}
    </FilterBarContainer>
  );
};

export default DealsHeader;
