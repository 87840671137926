import styled, { css, keyframes } from 'styled-components';

const slideIn = keyframes`
  from { transform: translateX(100%); }
  to { transform: translateX(0); }
`;

const slideOut = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(100%); }
`;

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1000;
`;

export const ModalWrapper = styled.div`
    background: white;
    border-radius: 10px 0px 0px 10px;
    // display: ${props => props.animationState === "closed" ? 'none':'unset'};
    width: 500px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: auto;
    z-index: 1001;
    // padding: 20px;
    gap: 2px;
    animation: ${props => props.isOpen ? css`${slideIn} 0.3s forwards` : css`${slideOut} 0.3s forwards`};
`;