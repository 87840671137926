// InfoModality.js
import React, {useContext} from 'react';
import { UserContext } from '../../../context/userContext.js';
import {
    Keys,
    FlexDiv,
    InfoContainer,
    GridContainer,
    Values,
} from './info-modality.styles'; // Make sure to use the correct path for importing the styled components
import { 
    TimeList,
    TimeItem,
    ConfirmButton,
    MoreActionsButton,
    AgentName,
    StatusBadge,
    BulletIcon,
    // CompletedButton
} from '../shared-modality.styles'

import moment from 'moment'

import { toProperCase } from '../../../utilities/utility.js'


const InfoModality = ({ showingData, setActiveTab }) => {
    const { user } = useContext(UserContext)
    // Helper function to format the date as "Jan 12, 2023".
    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };
    
    // Helper function to format the time with "AM/PM".
    const formatTime = (date) => {
        const options = { hour: '2-digit', minute: '2-digit' };
        return date.toLocaleTimeString('en-US', options);
    };
    
    // Function to sort datetime strings.
    const sortDateTime = (dateTimeArray) => {
        return dateTimeArray.sort((a, b) => {
        return new Date(a) - new Date(b);
        });
    };
    
    // This function processes the sorted array of datetime strings and groups the times by date.
    const processDateTimes = (dateTimeArray) => {
        const sortedDateTimeArray = sortDateTime(dateTimeArray);
        const dateTimesMap = sortedDateTimeArray.reduce((acc, dateTimeStr) => {
            const dateObj = new Date(dateTimeStr);
            const date = formatDate(dateObj); // Format the date as "Jan 12, 2023".
            const time = formatTime(dateObj); // Format the time with "AM/PM".
            // Initialize an array if this date hasn't been seen before, or push the time onto the existing array.
            (acc[date] = acc[date] || []).push(time);
            return acc;
        }, {});
        return dateTimesMap;
    };

    // Call the processDateTimes function with the showingData.requestedTimes array.
    const dateTimesMap = processDateTimes(showingData.requestedTime);

    // console.log(showingData)
    return (
        <InfoContainer>
            <GridContainer>   
                <AgentName>{showingData.agent.name}</AgentName>
                <StatusBadge status={showingData.status}>
                    <BulletIcon />
                    {showingData.status}
                </StatusBadge>
                {
                    showingData?.agent?.email &&
                    <>
                        <Keys>Agent Email</Keys>
                        <Values>{showingData?.agent?.email}</Values>
                    </>
                }
                {
                    showingData?.agent?.phone &&
                    <>
                        <Keys>Agent Phone</Keys>
                        <Values>{showingData?.agent?.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}</Values>
                    </>
                }
                {
                    (showingData.status === 'Upcoming' || showingData.status === 'Completed')  && 
                    <>
                        <Keys>Confirmed Time</Keys>
                        <Values>{moment(showingData.confirmedTime).format('MMM DD, yyyy hh:mm A')}</Values>
                    </>
                }
                {
                }
                {
                     (showingData.status === 'Rescheduled' || showingData.status === 'Agent Reschedule' ) &&
                     <>
                        <Keys>Rescheduled Time</Keys>
                        <Values>{moment(showingData.rescheduledTime).format('MMM DD, yyyy hh:mm A')}</Values>
                     </> 
                }
                {
                    showingData?.reason && 
                    <>
                        <Keys>Reason</Keys>
                        <Values>{showingData?.reason}</Values>
                    </>
                }
                {
                    (showingData.status === 'Upcoming' || showingData.status === 'Completed' || showingData.status === 'Rescheduled' || showingData.status === 'Agent Reschedule' )
                     && showingData.lockbox !== '' && showingData.lockbox !== undefined &&
                    <>
                        <Keys>Key Info</Keys>
                        <Values>{showingData.lockbox}</Values>
                    </>
                }               
                <Keys>Requested Time</Keys>
                <Values style = {{display:'flex', flexDirection:'column', gap:'10px'}}>  
                    {
                        Object.entries(dateTimesMap).map(([date, times]) => {
                            return (
                                // console.log(date, times)
                                <div key={date}>
                                <p>{date}</p>
                                <TimeList>
                                    {
                                        times.map((time, index) => (
                                            <TimeItem 
                                                key={index}
                                            >
                                                {time}
                                            </TimeItem>
                                            )
                                        )
                                    }
                                </TimeList>
                                </div>
                            )
                        })
                    }
                </Values>
                
                {
                    (showingData?.noticePeriod && showingData.status !== "Declined" && showingData.status !== "Cancelled" && showingData.status !== "Completed" && showingData.noticePeriod[0] != '-') &&
                    <>
                        <Keys>Notice Time</Keys>
                        <Values>{toProperCase(showingData.noticePeriod)}</Values>
                    </>
                }
                <Keys>Applicant Type</Keys>
                <Values>{showingData.applicantType}</Values>
                <Keys>Guarantor</Keys>
                <Values>{toProperCase(showingData.guarantor)}</Values>
                <Keys>Property Shown</Keys>
                <Values>{showingData.propertyShown}</Values>
                {
                    showingData.status !== 'New' && showingData.remark !== '' && showingData.remark !== undefined && showingData.remark !== null &&
                    <>
                        <Keys>Remarks</Keys>
                        <Values>{showingData.remark}</Values>
                    </>
                } 
            </GridContainer>
            <FlexDiv>
                {
                    user?.userType !== 'view_only' && showingData.status !== "Cancelled" && showingData.status !== "Completed" && showingData.status !== "Declined" && 
                    <MoreActionsButton onClick = {() => setActiveTab('other')}>Intervene</MoreActionsButton>
                }
                {
                    user?.userType !== 'view_only' && showingData.status !== "Upcoming" && showingData.status !== "Completed" && showingData.status !== "Declined" 
                    && showingData.status !== "Rescheduled" && showingData.status !== "Cancelled" && 
                    <ConfirmButton onClick = {() => setActiveTab('confirm')}>Confirm</ConfirmButton>
                }
                {/* {
                    showingData.status === 'Completed' &&
                    <CompletedButton>Completed</CompletedButton>
                } */}
            </FlexDiv>
        </InfoContainer>
    );
};

export default InfoModality;
