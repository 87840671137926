import React, { useContext, useEffect, useState, useRef } from "react";
import DealCard from "../../components/deal-card/deal-card";
import DealDetail from "../../components/deal-detail-content/deal-detail-content";
import DealsHeader from "../../components/deals-header/deals-header";
import DealDetailHeader from "../../components/deal-detail-header/deal-detail-header";
import CreateLeaseModality from "../../components/create-lease-modality/create-lease-modality";
import { useLocation } from "react-router-dom";
import { ContentSection } from "./deals.pages.styles";
import { ApiContext } from "../../context/apiContext";
import PopupForm from "../../components/popup-form/popup-form";
import { toast } from "react-toastify";
import LoaderCard from "../../components/loading-cards/loading-card";

const PAGE_SIZE = 10;

const Deals = ({ headerHeight }) => {
  const api = useContext(ApiContext);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [deals, setDeals] = useState([]);
  const [focusDeal, setFocusDeal] = useState();
  const [modalId, setModalId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [forceFetch, setForceFetch] = useState(false);
  const isFirstRender = useRef(true);
  const [sortField, setSortField] = useState("");

  const [filterFields, setFilterFields] = useState({
    lease_status: "",
    payment_status: "",
    property: "",
    agent: "",
  });


  // Ref for observer (Pagination)
  const observerRef = useRef(null);

  const observerElement = (
    <div 
      ref={observerRef} 
      style={{ height: "20px" }}
    />
  );

  const handleClick = async (id) => {
    if (id) {
      try {
        const data = await api.get(`/api/deal/detail`, { dealId: id });
        window.history.pushState({}, "", `/deals/${id}`);
        setFocusDeal(data);
      } catch (error) {
        // Handle network or other errors
        toast.error(`There was an error loading the data: ${error.message}`);
      }
    } else {
      window.history.pushState({}, "", `/deals`);
      setFocusDeal();
    }
  };

  const fetchData = async (pageNumber) => {
    // if(!focusDeal && location.pathname.split('/deals/')[1]) setIsLoading(true); // Start loading
    try {
      const params = new URLSearchParams();
      params.append("page", page);
      params.append("size", PAGE_SIZE);

      if (filterFields.payment_status)
        params.append("payment_status", filterFields.payment_status);
      if (filterFields.lease_status)
        params.append("lease_status", filterFields.lease_status);
      if (filterFields.property)
        params.append("listing_id", filterFields.property);
      if (filterFields.agent) params.append("agent_id", filterFields.agent);
      if (sortField && sortField !== "") params.append("ordering", sortField);
      const apiURL = `/api/deal/all${
        params.toString() ? "?" + params.toString() : ""
      }`;

      const response = await api.get(apiURL);
      if (location.pathname.split("/deals/")[1]) {
        handleClick(location.pathname.split("/deals/")[1]);
      }
      if (pageNumber === 1) {
        setDeals(response.results);
      } else {
        setDeals((prev) => [...prev, ...response.results]);
      }
      if (response.next) setHasMore(true);
      else setHasMore(false);
    } catch (error) {
      setHasMore(false);
      toast.error(`There was an error loading the data: ${error.message}`);
    } finally {
        if (forceFetch) setForceFetch(false);
        setIsLoading(false);
    }
  };

  // Reset page and force fetch when `filterFields` or `sortField` changes, reset `page`
  // Fetches the initial data when the component mounts
  useEffect(() => {
    // Fetch data when the component mounts
    if (isFirstRender.current) {
      fetchData(1);
      isFirstRender.current = false;
      return;
    }
    setPage(1);
    setForceFetch(true);
  }, [filterFields, sortField]);

  // Fetch data when `page` changes
  useEffect(() => {
    if (page > 1 || forceFetch) fetchData(page);
  }, [page, forceFetch]);

  const toggleModal = async (id) => {
    setModalId(id);
    setIsModalOpen((prev) => !prev);
  };

  const updateDeal = (updateData, depositType = null) => {
    if (depositType) {
      // When payments are being added, only in focus
      setFocusDeal((prev) => ({
        ...prev,
        dealMoveinDeposits: {
          ...prev.dealMoveinDeposits,
          [depositType]: updateData[depositType],
        },
        amountDue: prev.amountDue - updateData[depositType].amount,
        amountPaid: prev.amountPaid + updateData[depositType].amount,
      }));
    } else {
      /// WHEN LEASE IS BEING ADDED
      if (focusDeal) {
        setFocusDeal((prev) => {
          return {
            ...prev,
            ...updateData,
          };
        });
      } else {
        setDeals((currentDeals) =>
          currentDeals.map((deal) =>
            deal.id == updateData.dealId ? { ...deal, ...updateData } : deal
          )
        );
      }
    }
  };


  // Infinite Scrolling
  useEffect(() => {
    const loadMoreEntries = (entries) => {
      const target = entries[0];
      if (target.isIntersecting && hasMore) {
        setPage((prev) => prev + 1);
      }
    };

    const observer = new IntersectionObserver(loadMoreEntries, {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    });


    if (observerRef.current) observer.observe(observerRef.current);

    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current);
    };
  }, [hasMore]);

  return (
    <>
      {!focusDeal ? (
        <>
          <DealsHeader
            filterFields={filterFields}
            setFilterFields={setFilterFields}
            setSortField={setSortField}
          />
          {isLoading ? (
            <ContentSection>
              <LoaderCard size="large" />
            </ContentSection>
          ) : (
            <ContentSection headerHeight={headerHeight}>
              {deals.map((deal) => (
                <DealCard
                  key={deal.id}
                  activateModal={() => toggleModal(deal.id)}
                  deal={deal}
                  handleClick={() => handleClick(deal.id)}
                />
              ))}
              {observerElement}
            </ContentSection>
          )}
        </>
      ) : (
        <>
          <DealDetailHeader
            deal={focusDeal}
            filteredDealIds={deals.map((deal) => deal.id)}
            setDeals={setDeals}
            handleClick={handleClick}
          />
          {isLoading ? (
            <ContentSection>
              <LoaderCard size="large" />
            </ContentSection>
          ) : (
            <ContentSection headerHeight={headerHeight}>
              <DealDetail
                deal={focusDeal}
                activateModal={() => toggleModal(focusDeal.id)}
                updateDeal={updateDeal}
              />
            </ContentSection>
          )}
        </>
      )}
      {isModalOpen &&
        (() => {
          // Find the deal once
          const deal = deals.find((deal) => deal.id === modalId);

          if (!deal) return null; // or handle the case where no deal is found

          if (deal.leaseDoc) {
            return (
              <PopupForm
                showPopupUrl={deal.leaseDoc}
                handleClose={() => {
                  setModalId(null);
                  toggleModal();
                }}
              />
            );
          } else {
            return (
              <CreateLeaseModality
                onClose={toggleModal}
                modalId={modalId}
                updateDeal={updateDeal}
              />
            );
          }
        })()}
    </>
  );
};

export default Deals;
