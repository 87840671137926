import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../context/userContext";
import {
    Title,
    FilterBarContainer,
    TabContentHeader,
    TabHolder,
    Tab
} from './applications-filter.styles'
import { ApiContext } from "../../context/apiContext";
import { toast } from 'react-toastify'

const ApplicationsFilter = ({ handleFilter}) => {

    const [focusTab, setFocusTab] = useState('New');
    const api = useContext(ApiContext);
    const [applicationsCount, setApplicationsCount] = useState({
        new: 0,
        underReview: 0,
        pending: 0,
        assignedToMe: 0,
        fmrPending: 0,
        all: 0
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await api.get("api/application/count");
                setApplicationsCount(
                    {
                        new: data.new,
                        underReview: data.under_review,
                        pending: data.pending,
                        assignedToMe: data.assigned_to_me,
                        fmrPending: data.fmr_pending,
                        all: data.all
                    }
                )
            } catch (error) {
                // The catch block now handles any errors thrown from the API request
                toast.error(`There was an error loading the data: ${error.message}`);
            }
        };

        fetchData();
    }, [])

    const onTabClick = (value) => {
        setFocusTab(value);
        handleFilter(value)
    }

    return (
        <FilterBarContainer>
            <Title>Applications</Title>
            <TabContentHeader>
                <TabHolder>
                    <Tab
                        onClick={() => onTabClick('All')} active={focusTab === "All"}
                    >
                        All Applications <span>{applicationsCount.all}</span>
                    </Tab>
                    <Tab
                        onClick={() => onTabClick('New')} active={focusTab === "New"}
                    >
                        New <span>{applicationsCount.new} </span>
                    </Tab>
                    <Tab
                        onClick={() => onTabClick('Under Review')} active={focusTab === "Under Review"}
                    >
                        Under Review <span>{applicationsCount.underReview}</span>
                    </Tab>
                    <Tab
                        onClick={() => onTabClick('Assigned To Me')} active={focusTab === "Assigned To Me"}
                    >
                        Assigned To Me <span>{applicationsCount.assignedToMe}</span>
                    </Tab>
                    <Tab
                        onClick={() => onTabClick('Deposit Pending')} active={focusTab === "Deposit Pending"}
                    >
                        Deposit Pending <span>{applicationsCount.fmrPending}</span>
                    </Tab>
                    <Tab
                        onClick={() => onTabClick('Pending')} active={focusTab === "Pending"}
                    >
                        Pending <span>{applicationsCount.pending}</span>
                    </Tab>
                </TabHolder>
            </TabContentHeader>
        </FilterBarContainer>
    )
}

export default ApplicationsFilter