import React, { useMemo, useState, useEffect, useContext } from "react";
import ChatChannel from "../agent-chat/agent-chat";
import DocumentStatusList from "../document-status-list/document-status-list";
import ApplicationSummary from "../application-summary/application-summary";
import EditApplicantsModal from "../edit-application-modal/edit-application-modal"; // Import the modal component
import ChangeApplication from "../change-application/change-application";
import UpdateRentModal from "../update-rent-modal/update-rent-modal";
import { toast } from "react-toastify";
import { formatDate } from "../../utilities/utility";
import { ReactComponent as Download } from "../../assets/Download.svg";
import {
  Canvas,
  Title,
  StatusBadge,
  BulletIcon,
  Tab,
  TabContentHeader,
  TabHolder,
  SubmittedDate,
  BorderContainer,
  Heading,
  CHatContainer,
  Disclaimer,
  ChatHeader,
  TitleAndBadgeContainer,
  DocumentsContainer,
  HeadingContainer,
  StyledTable,
  TableCellWrapper,
  TableRow,
  Th,
  ApplicantName,
  LeaseStatusBadge,
  LeaseDownload,
  AddButton,
} from "./agent-application-status.styles";
import {
  CancelButton,
  ConfirmButton,
} from "../edit-application-modal/edit-application-modal.styles";
import ApplicantModal from "../agent-application-form/applicant-modal";
import {
  AttachmentBox,
  PreviewContainer,
  PDFIconWrapper,
  FileName,
  FileUploadLabel,
  RoundDiv,
  HiddenFileInput,
  UploadInstruction
} from "../agent-application-form/shared-styles";

import { OpenButton, DeleteButton } from "../agent-application-form/add-applicant-package/add-applicant-package.styles";
import {ReactComponent as Document} from "../../assets/Document.svg";
import { ApiContext } from "../../context/apiContext";


let applicantId = -1000;

const AgentApplicationStatus = ({
  applicationData,
  setApplicationData,
  agentId,
}) => {
  console.log("main", applicationData);
  const api = useContext(ApiContext);
  const [focusTab, setFocusTab] = useState("Summary");
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [editType, setEditType] = useState("applicants"); // State to track if editing applicants or guarantors
  const [applicants, setApplicants] = useState(applicationData.applicants);
  const [guarantors, setGuarantors] = useState(
    applicationData.applicants
      .filter((applicant) => applicant.hasCosigner)
      .map((applicant) => applicant.cosigner)
  );
  const [applicantsCopy, setApplicantsCopy] = useState(
    applicationData.applicants
  );
  const [guarantorsCopy, setGuarantorsCopy] = useState(
    applicationData.applicants
      .filter((applicant) => applicant.hasCosigner)
      .map((applicant) => applicant.cosigner)
  );
  const [otherApplicationState, setOtherApplicationState] = useState();
  const [updateRentModal, setUpdateRentModal] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [applicantModalOpen, setApplicantModalOpen] = useState(false);
  const [applicantModalMode, setApplicantModalMode] = useState(""); // Add or Update
  const [editApplicant, setEditApplicant] = useState({});
  const [editApplicantDocuments, setEditApplicantDocuments] = useState(
    new Set()
  );

  const [applicationPackageState, setApplicationPackageState] = useState(null);
  const [applicationPackage, setApplicationPackage] = useState();
  const [previewUrl, setPreviewUrl] = useState();

  useEffect(() => {
    // Add a condition to update only when necessary
    if (
      JSON.stringify(applicantsCopy) !==
      JSON.stringify(applicationData.applicants) // Compare current and new applicants
    ) {
      setApplicants([...applicationData.applicants]);
      setGuarantors(
        applicationData.applicants
          .filter((applicant) => applicant.hasCosigner)
          .map((applicant) => applicant.cosigner)
      );
      setApplicantsCopy([...applicationData.applicants]);
      setGuarantorsCopy(
        applicationData.applicants
          .filter((applicant) => applicant.hasCosigner)
          .map((applicant) => applicant.cosigner)
      );
    }

    // If applicationPackage value is available, set it to the state
    console.log(
      "applicationPackage value first render",
      applicationData?.applicationPackage?.value
    );
    if (applicationData?.applicationPackage?.value) {
      console.log("setting the value");
      setApplicationPackageState(applicationData.applicationPackage.value);
    }
  }, [applicationData]);

  const toggleModal = (type) => {
    setEditType(type);
    setIsModalOpen(!isModalOpen);
  };

  const applicantApplicationDocuments = useMemo(() => {
    const docsByApplicant = applicants.map((applicant, index) => {
      const listDocs = applicationPackageState
        ? applicant.requiredDocs
        : ["Application Form", ...applicant.requiredDocs];
      const docs = listDocs.reduce((acc, doc) => {
        // Use an empty array if applicant.documents is undefined or not an array
        const submittedDocuments = Array.isArray(applicant.documents)
          ? applicant.documents
          : [];
        console.log(submittedDocuments);
        // Find the submitted document that matches the required doc
        const submittedDoc = submittedDocuments.find(
          (submitted) => submitted.documentType === doc
        );
        // Assign the document value if submitted, or null if not found
        acc[doc] = submittedDoc ? submittedDoc.value : null;
        return acc;
      }, {});
      // Return an object keyed by applicant name or a unique identifier with their docs
      return {
        [applicant.name]: {
          documents: docs,
          appLink: applicationPackageState
            ? null
            : applicant.applicationFormLink,
          data: applicant,
        },
      };
    });

    // Convert the array of applicants into a single object
    return docsByApplicant.reduce(
      (acc, current) => ({ ...acc, ...current }),
      {}
    );
  }, [applicants]);

  const guarantorApplicationDocuments = useMemo(() => {
    const docsByGuarantor = applicants
      .map((applicant, index) => {
        if (applicant.hasCosigner) {
          const listDocs = applicationPackageState
  ? applicant.cosigner.requiredDocs || []
  : ["Guarantor Form", ...(applicant.cosigner.requiredDocs || [])];
          const docs = listDocs.reduce((acc, doc) => {
            if (applicationPackageState) return {};

            // Find the submitted document that matches the required doc
            const submittedDoc = applicant?.cosigner?.documents?.find(
              (submitted) => submitted.documentType === doc
            );
            // Assign the document value if submitted, or null if not found
            acc[doc] = submittedDoc ? submittedDoc.value : null;
            return acc;
          }, {});
          // Return an object keyed by applicant name or a unique identifier with their docs
          return {
            [applicant.cosigner.name]: {
              documents: docs,
              appLink: applicationData.applicationPackage?.value
                ? null
                : applicant.cosigner.guarantorFormLink,
            },
          };
        }
      })
      .filter(Boolean);

    return docsByGuarantor.reduce(
      (acc, current) => ({ ...acc, ...current }),
      {}
    );
  }, [applicants]);

  const triggerDownload = () => {
    const fileURL =
      window.location.origin + "/media/" + applicationData.lease.leaseDoc;
    const fileLink = document.createElement("a");

    fileLink.href = fileURL;
    fileLink.setAttribute("download", "download.pdf"); // Specify the file name and extension
    document.body.appendChild(fileLink);

    fileLink.click();

    window.URL.revokeObjectURL(fileURL); // Clean up: revoke the blob URL
    fileLink.remove(); // Remove the temporary link element
  };

  const updateApplication = (data) => {
    // console.log(data)
    // (data) => setApplicationData(prev => ({...prev, data}))
    setApplicationData((prev) => ({
      ...prev,
      offerMoveinDeposits: data.moveinDeposits,
      offerPrice: data.offerPrice,
    }));
  };

  const handleApplicantAdd = () => {
    setApplicantModalMode("add");
    setEditApplicant({
      id: applicantId--,
      name: "",
      email: "",
      applicantType: "",
      hasCosigner: false,
      cosigner: {
        name: "",
        email: "",
        requiredDocs: [],
      },
    });
    setApplicantModalOpen(true);
  };

  const handleApplicantEdit = (applicantData) => {
    setApplicantModalMode("edit");
    setEditApplicant({
      id: applicantData.id,
      name: applicantData.name,
      email: applicantData.email,
      applicantType: applicantData.applicantType,
      hasCosigner: applicantData.hasCosigner,
      cosigner: {
        name: applicantData?.cosigner?.name || "",
        email: applicantData?.cosigner?.email || "",
      },
    });
    setEditApplicantDocuments(new Set(applicantData.requiredDocs));
    setApplicantModalOpen(true);
  };

  const performApplicantDelete = (applicantData) => {
    const updatedApplicants = applicants.filter(
      (applicant) => applicant.id !== applicantData.id
    );
    setApplicants(updatedApplicants);
  };

  const submittedDate = formatDate(
    new Date(applicationData?.submittedTimeStamp)
  ).formattedDate;
  const submittedTime = formatDate(
    new Date(applicationData?.submittedTimeStamp)
  ).formattedTime;

  const performApplicantEditOrAdd = () => {
    if (applicantModalMode === "edit") {
      const updatedApplicants = applicants.map((applicant) => {
        if (applicant.id === editApplicant.id) {
          return {
            ...applicant,
            name: editApplicant.name,
            email: editApplicant.email,
            applicantType: editApplicant.applicantType,
            hasCosigner: editApplicant.hasCosigner,
            cosigner: {
              name: editApplicant.cosigner.name,
              email: editApplicant.cosigner.email,
            },
            requiredDocs: Array.from(editApplicantDocuments),
          };
        }
        return applicant;
      });
      setApplicants(updatedApplicants);
    } else {
      setApplicants([
        ...applicants,
        {
          ...editApplicant,
          requiredDocs: Array.from(editApplicantDocuments),
        },
      ]);
    }
    setApplicantModalOpen(false);
  };

  const handleCancelChanges = () => {
    // Firstly prompt the user to confirm the action
    if (window.confirm("Are you sure you want to discard the changes?")) {
      // Reset the applicants and guarantors to their original state
      setApplicants([...applicantsCopy]);
      setGuarantors([...guarantorsCopy]);
      setEditMode(false);
    }
  };

  const saveData = async () => {
    console.log("applicationPackage", applicationPackage);
      try {
          const data = await api.post('api/application/update', {
              applicationId: applicationData.id,
              applicants: applicants,
              applicationPackage: applicationPackage,
          })
          toast.info('Changes saved successfully');
      }catch (error) {
          toast.error('Something didnt work like it was supposed to:', error.message);
      } 
  }

  const handleSaveChanges = () => {
    // Save the changes to the server
    // Then reset the edit mode
    saveData();
    setEditMode(false);
  };

  const handleFileUploadApplicationPackage = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf') {
        const objectUrl = URL.createObjectURL(file);
        setPreviewUrl(objectUrl);
        setApplicationPackage(file);
    }
};


const onRemoveApplicationPackage = () => {
    setPreviewUrl('');
    setApplicationPackage();
};

  const status =
    applicationData.status === "Pending"
      ? "Incomplete"
      : applicationData.status === "New"
      ? "Submitted"
      : applicationData.status;
  return (
    <>
      <Canvas>
        {!otherApplicationState ? (
          <>
            <TitleAndBadgeContainer>
              <Title>Application Status</Title>
              <StatusBadge status={status}>
                <BulletIcon />
                {status}
              </StatusBadge>

              {editMode == false ? (
                <AddButton onClick={() => setEditMode(true)}>
                  Edit Application
                </AddButton>
              ) : (
                <div className="h-12 flex gap-1 justify-center items-center">
                  <CancelButton onClick={handleCancelChanges}>
                    Cancel
                  </CancelButton>
                  <ConfirmButton onClick={handleSaveChanges}>
                    Save
                  </ConfirmButton>
                </div>
              )}
            </TitleAndBadgeContainer>
            <TabContentHeader>
              <TabHolder>
                <Tab
                  onClick={() => setFocusTab("Summary")}
                  active={focusTab === "Summary"}
                >
                  Summary
                </Tab>
                <Tab
                  onClick={() => setFocusTab("Documents")}
                  active={focusTab === "Documents"}
                >
                  Documents
                </Tab>
                <Tab
                  onClick={() => setFocusTab("Chat")}
                  active={focusTab === "Chat"}
                >
                  Chat
                </Tab>
                {applicationData.status === "Approved" &&
                  applicationData?.lease?.leaseDoc && (
                    <Tab
                      onClick={() => setFocusTab("Lease")}
                      active={focusTab === "Lease"}
                    >
                      Lease
                    </Tab>
                  )}
              </TabHolder>
              <SubmittedDate>
                Submitted On: {submittedDate} {submittedTime}
              </SubmittedDate>
            </TabContentHeader>
            {focusTab === "Summary" && (
              <BorderContainer>
                <ApplicationSummary
                  property={applicationData.property}
                  applicationData={applicationData}
                  openModal={() => setUpdateRentModal(true)}
                  offerMoveinDeposits={applicationData.offerMoveinDeposits}
                  setOtherApplicationState={setOtherApplicationState}
                />
              </BorderContainer>
            )}
            {focusTab === "Documents" && (
              <>
                <BorderContainer>
                  {applicationPackageState && (
                    <HeadingContainer>
                      <Heading>Application Package</Heading>
                      <DocumentsContainer>
                        <DocumentStatusList
                          applicantKey="Application Package"
                          documents={{
                            "Complete Package": applicationPackageState,
                          }}
                        />
                      </DocumentsContainer>
                    </HeadingContainer>
                  )}
                  {editMode && applicationPackageState && (
                    <HeadingContainer>
                      <Heading>Update Application Package</Heading>
                      {previewUrl ? (
                        <AttachmentBox>
                          <PreviewContainer>
                            <PDFIconWrapper>
                              <Document />{" "}
                              {/* Use a PDF icon or SVG component */}
                            </PDFIconWrapper>
                            <FileName>{applicationPackage.name}</FileName>
                            <OpenButton
                              onClick={() => window.open(previewUrl, "_blank")}
                            >
                              Open
                            </OpenButton>
                            <DeleteButton onClick={onRemoveApplicationPackage}>
                              Remove
                            </DeleteButton>
                          </PreviewContainer>
                        </AttachmentBox>
                      ) : (
                        <FileUploadLabel htmlFor="file-upload">
                          <RoundDiv>
                            <Document />
                          </RoundDiv>
                          <HiddenFileInput
                            id="file-upload"
                            type="file"
                            accept="application/pdf"
                            onChange={handleFileUploadApplicationPackage}
                          />
                          <UploadInstruction>
                            Click to Upload
                            <br />
                            (Max. File size: 5 MB)
                          </UploadInstruction>
                        </FileUploadLabel>
                      )}
                    </HeadingContainer>
                  )}
                  <HeadingContainer>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Heading>Applicants</Heading>
                      {editMode && (
                        <AddButton onClick={handleApplicantAdd}>
                          Add Applicant
                        </AddButton>
                      )}
                    </div>
                    <DocumentsContainer>
                      {Object.entries(applicantApplicationDocuments).map(
                        ([applicantKey, value]) => (
                          <DocumentStatusList
                            applicantKey={applicantKey}
                            documents={value.documents}
                            appLink={value.appLink}
                            editMode={editMode}
                            handleEdit={() => handleApplicantEdit(value.data)}
                            handleDelete={() =>
                              performApplicantDelete(value.data)
                            }
                          />
                        )
                      )}
                    </DocumentsContainer>
                  </HeadingContainer>
                  {Object.keys(guarantorApplicationDocuments).length > 0 && ( // Assuming this is meant to check if the data is available
                    <HeadingContainer>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Heading>Guarantors</Heading>
                        {/* <AddButton onClick={() => toggleModal("guarantors")}>
                          Edit Guarantors
                        </AddButton> */}
                      </div>
                      <DocumentsContainer>
                        {Object.entries(guarantorApplicationDocuments).map(
                          ([applicantKey, value]) => (
                            <DocumentStatusList
                              applicantKey={applicantKey}
                              documents={value.documents}
                              appLink={value.appLink}
                            />
                          )
                        )}
                      </DocumentsContainer>
                    </HeadingContainer>
                  )}
                </BorderContainer>
                <Disclaimer>
                  *The application package is auto sent to the management once
                  all documents are submitted.
                </Disclaimer>
              </>
            )}
            {focusTab === "Chat" && applicationData.status === "Pending" ? (
              <CHatContainer>
                <div
                  style={{
                    display: "flex",
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "20px",
                    color: "#747474",
                    height: "100%",
                    padding: "30px",
                  }}
                >
                  Chat enabled when application is submitted by all applicants.
                </div>
              </CHatContainer>
            ) : (
              focusTab === "Chat" &&
              applicationData.status !== "Pending" && (
                <CHatContainer>
                  <ChatHeader>Chat</ChatHeader>
                  <ChatChannel
                    agentId={applicationData.agent.id}
                    groupId={applicationData.externalGroupId}
                  />
                </CHatContainer>
              )
            )}
            {focusTab === "Lease" && (
              <BorderContainer>
                {applicationData?.lease?.leaseDoc && (
                  <div
                    style={{
                      justifyContent: "space-between",
                      display: "flex",
                    }}
                  >
                    <Heading>Lease Document</Heading>
                    <LeaseDownload onClick={triggerDownload}>
                      Download <Download />
                    </LeaseDownload>
                  </div>
                )}
                <div
                  style={{ justifyContent: "space-between", display: "flex" }}
                >
                  <Heading>Landlord</Heading>
                  <LeaseStatusBadge
                    status={applicationData?.lease?.landlordSignatureStatus}
                  >
                    <BulletIcon />
                    {applicationData?.lease?.landlordSignatureStatus}
                  </LeaseStatusBadge>
                </div>
                <HeadingContainer>
                  <Heading>Applicants</Heading>
                  <StyledTable>
                    <thead style={{ borderBottom: "1px solid #f1f1f1" }}>
                      <TableRow>
                        <Th>
                          <TableCellWrapper>Name</TableCellWrapper>
                        </Th>
                        <Th>
                          <TableCellWrapper>Lease Status</TableCellWrapper>
                        </Th>
                      </TableRow>
                    </thead>
                    <tbody>
                      {applicationData?.lease?.applicantLeaseStatus?.map(
                        (applicant) => (
                          <TableRow>
                            <ApplicantName>
                              <TableCellWrapper>
                                {applicant.name}
                              </TableCellWrapper>
                            </ApplicantName>
                            <td style={{ width: "fit-content" }}>
                              <TableCellWrapper>
                                <LeaseStatusBadge
                                  status={applicant.leaseStatus}
                                >
                                  <BulletIcon />
                                  {applicant.leaseStatus}
                                </LeaseStatusBadge>
                              </TableCellWrapper>
                            </td>
                          </TableRow>
                        )
                      )}
                    </tbody>
                  </StyledTable>
                </HeadingContainer>
                {applicationData?.lease?.guarantorLeaseStatus?.length > 0 && (
                  <HeadingContainer>
                    <Heading>Guarantors</Heading>
                    <StyledTable>
                      <thead style={{ borderBottom: "1px solid #f1f1f1" }}>
                        <TableRow>
                          <Th>
                            <TableCellWrapper>Name</TableCellWrapper>
                          </Th>
                          <Th>
                            <TableCellWrapper>Lease Status</TableCellWrapper>
                          </Th>
                        </TableRow>
                      </thead>
                      <tbody>
                        {applicationData?.lease?.guarantorLeaseStatus?.map(
                          (guarantor) => (
                            <TableRow>
                              <ApplicantName>
                                <TableCellWrapper>
                                  {guarantor.name}
                                </TableCellWrapper>
                              </ApplicantName>
                              <td style={{ width: "fit-content" }}>
                                <TableCellWrapper>
                                  <LeaseStatusBadge
                                    status={guarantor.leaseStatus}
                                  >
                                    <BulletIcon />
                                    {guarantor.leaseStatus}
                                  </LeaseStatusBadge>
                                </TableCellWrapper>
                              </td>
                            </TableRow>
                          )
                        )}
                      </tbody>
                    </StyledTable>
                  </HeadingContainer>
                )}
              </BorderContainer>
            )}
          </>
        ) : (
          <ChangeApplication
            setOtherApplicationState={setOtherApplicationState}
            otherApplicationState={otherApplicationState}
            applicationData={applicationData}
          />
        )}
        <EditApplicantsModal
          isOpen={isModalOpen}
          toggle={toggleModal}
          type={editType}
          applicants={editType === "applicants" ? applicants : guarantors}
          // updateApplicants={updateApplicants}
          applicationId={applicationData.id}
        />
        {updateRentModal && (
          <UpdateRentModal
            focusApplication={applicationData}
            onClose={() => setUpdateRentModal(false)}
            updateApplication={updateApplication}
            agentId={agentId}
          />
        )}
      </Canvas>
      {applicantModalOpen && (
        <ApplicantModal
          isOpen={applicantModalOpen}
          onClose={() => setApplicantModalOpen(false)}
          applicant={editApplicant}
          applicantDocuments={editApplicantDocuments}
          setApplicant={setEditApplicant}
          setApplicantDocuments={setEditApplicantDocuments}
          handleSave={performApplicantEditOrAdd}
          mode={applicantModalMode}
          applicationPackageState={applicationPackageState}
        />
      )}
    </>
  );
};

export default AgentApplicationStatus;
