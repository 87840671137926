import React, { useState, useContext } from "react";
import FormInput from "../../components/form-input/form-input";
import { RadioButtonLabel } from "../../components/agent-application-form/shared-styles";
import { ApiContext } from "../../context/apiContext";
import { toast } from "react-toastify";
import {
  InputDivLabel,
  Heading,
} from "../../components/applicant-form/shared-styles";
import UploadSection from "../../components/upload-file/upload-file";

const CreateClientForm = ({}) => {
  const [formData, setFormData] = useState({
    domain_name: "",
    client_id: "",
    client_name: "",
    client_email: "",
    client_communication_email: "",
    ygl_key: "",
    ygl_tag: "CRMP",
    is_ygl: true,
    is_showing_enabled: true,
    is_auto_confirm_enabled: true,
    is_tenant_notification: false,
    is_tenant_permission: true,
    is_default_lockbox: true,
    default_lockbox:
      "Please collect the keys from our office at 500 Lincoln St., 2nd Floor, Allston, MA 02134",
    notice_window_in_hrs: 48,
    is_deal_enabled: true,
    is_lease_enabled: true,
    is_depositlink_enabled: true,
    is_buildium_enabled: false,
    is_appfolio_enabled: false,
    is_countersign_trigger_enabled: false,
    is_guarantor_signature_required: false,
    is_landlord_embed_signing: true,
    access_token: "-",
    is_payment_enabled: true,
    commission_type: "Property Manager",
    auto_generate_invoice: false,
  });

  const api = useContext(ApiContext);

  const handleSubmit = async () => {
    const payload = formData;

    try {
      const response = await api.post(`/api/create-client/`, payload);
      toast.success("Client created successfully");
    } catch (error) {
      toast.error("Error during the create operation", error);
    }
  };

  const [docs, setDocs] = useState([]);

  return (
    <div className="p-4 gap-2 flex flex-col flex-1">
      <h1 className="text-5xl font-bold text-center py-4">Setup Client</h1>
      <div className="grid grid-cols-2 gap-4">
        {Object.keys(formData).map((field) => {
          const value = formData[field];
          return (
            typeof value === "string" &&
            field !== "default_lockbox" && (
              <FormInput
                label={field
                  .toString()
                  .replace(/_/g, " ")
                  .replace(/\b\w/g, (c) => c.toUpperCase())}
                type="text"
                defaultValue={value}
                handleChange={(value) =>
                  setFormData((prev) => ({
                    ...prev,
                    [field]: value,
                  }))
                }
                flexBasis={true}
              />
            )
          );
        })}
        <div className="col-span-2">
          <FormInput
            label="Default Lockbox"
            type="text"
            defaultValue={formData.default_lockbox}
            handleChange={(value) =>
              setFormData((prev) => ({
                ...prev,
                default_lockbox: value,
              }))
            }
            flexBasis={true}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4 py-4">
        {
          // For all boolean fields, use RadioButtonLabel
          Object.keys(formData).map((field) => {
            const value = formData[field];
            return (
              typeof value === "boolean" && (
                <RadioButtonLabel key={field}>
                  <input
                    type="checkbox"
                    name={field}
                    checked={formData[field] === true}
                    onChange={(e) =>
                      setFormData((prev) => ({ ...prev, [field]: !value }))
                    }
                  />
                  {field
                    .toString()
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                </RadioButtonLabel>
              )
            );
          })
        }
      </div>
      <div>
        <div className="flex flex-1 gap-6 justify-between"
          // style={{
          //   flex: "1",
          //   display: "flex",
          //   flexDirection: "row",
          //   gap: "10px",
          //   width: "fit-content",
          //   flexBasis: "300px",
          // }}
        >
          <div>
            <Heading>
              Upload Company's W9
              {/* <span style={{ color: "red" }}>*</span> */}
            </Heading>
            <InputDivLabel>
              (Max. File size: 5 MB and it must be .pdf)
            </InputDivLabel>
            <UploadSection index={1} setDocs={setDocs} doc={"W9"} />
          </div>

          <div>
            <Heading>
              Upload Company's Logo
              {/* <span style={{ color: "red" }}>*</span> */}
            </Heading>
            <InputDivLabel>
              (Max. File size: 5 MB and it must be .jpg or .png)
            </InputDivLabel>
            <UploadSection index={2} setDocs={setDocs} doc={"W9"} />
          </div>
          <div/><div/>
        </div>
      </div>
      <div className="flex justify-center">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default CreateClientForm;
