import React, { useState, useEffect, useContext } from "react";
import { Dropdown } from "../showing-filter/showing-filter.styles";
import { ApiContext } from "../../context/apiContext";
import { toast } from "react-toastify";

const YearFilter = () => {
  const {year, setYear} =  useContext(ApiContext);
  const currentYear = new Date().getFullYear();

  const years = Array.from({ length: 5 }, (_, i) => currentYear - i);


  const handleChange = (e) => {
    setYear(e.target.value);
    window.location.reload();
  };

  return (
    <Dropdown onChange={handleChange} value={year}>
      {years.map((year) => (
        <option key={year} value={year}>
          {year}
        </option>
      ))}
    </Dropdown>
  );
};

export default YearFilter;
